.goog-logo-link {
    display:none !important;
} 
    
.goog-te-gadget{
    color: transparent !important;
}

.goog-te-combo {
    z-index: 99;
    margin-top: 20px !important;
    width: 150px;
    padding: 7px 8px;
    color: #737c83;
    border-radius: 4px;
    margin-right: 13px;
    border: 1px solid #ececeb;
}