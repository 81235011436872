.purple {
    width: 50px;
    height: 50px;
    background: #d4dbf9;
    border-radius: 100%;
    padding: 13px 20px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}


.media{
    display: flex;
}
#evs-wrap .card{
    background-color: #f2f2f2;
}
.font-btn{
    padding: 3px 5px !important;
}