#tab-wrapper .tab-content{
    border: 1px solid #f2f2f2;
    padding: 20px 15px;
}

/* #tab-wrapper .nav-tabs .nav-link.active{
    color: #0080ff;
    background-color: #ffffff;
    border-color: #ffffff #ffffff #0080ff;
} */
#tab-wrapper .nav-tabs .nav-link:hover{
    color: #0080ff;
    border-color: #ffffff #ffffff #0080ff;
}
#tab-wrapper .nav-tabs .nav-link {
    border-bottom-width: 2px;
    color: #5d5e63;
}
#tab-wrapper .nav-tabs {
    margin: 0px !important;
}
#tab-wrapper .tab-wrap h5{
    font-size: 19px;
    color: #2A3042;
    margin-top: 0.5rem;
}
#tab-wrapper .edit-btn{
    position: absolute;
    right: 26px;
}
.user-edit-img{
    position: absolute;
}

.card-margin{
    margin: 0px 23px 40px 23px !important;
}


.iamusertabPleft{
    padding: 0px 15px !important;
}