.modal.calnderModal .modal-dialog{
    max-width: 500px !important;
}
.name-ellipsis{
    display: block;
    width: 252px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.rbc-header{

    padding: 14px 3px !important;
}
.rbc-event, .rbc-day-slot .rbc-background-event{
    border-radius: 20px !important;
    text-align: center !important;
    font-size: 15px;
}
.rbc-toolbar button:active, .rbc-toolbar button.rbc-active{
    background-color: #355EDF !important;
    border-color: #355EDF !important;
    color: #fff;
    box-shadow: none !important;
}
.rbc-toolbar button{
    background: #556EE6 !important;
    border: 1px solid #556EE6 !important;
    color: #fff !important;
}
.rbc-toolbar button:focus{
    outline: none !important;
}
.rbc-toolbar .rbc-toolbar-label{
    font-size: 23px;
}
.calnderModal .view-detail{
    padding: 3px;
}