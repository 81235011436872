.login-with-google-btn {
    background-color: rgb(255, 255, 255);
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    border: 1px solid rgb(210, 210, 210) !important;
    padding: 0px;
    border-radius: 2px;
    border: 1px solid rgb(210, 210, 210);
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    font-size: 16px !important;
    padding: 0px 77px !important;
}