.blue-page1#bluePage-wrap{
    background-image: url('../../assets/bg-world.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 70px;
}



.blue-page1#bluePage-wrap .main-head{

    color: #ED4335;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 3px;
    text-align: center;

}
.blue-page1#bluePage-wrap .sub-head{
    color: #121212;
    font-size: 21px;
    text-align: center;
}

.blue-page1#bluePage-wrap .pointer-wrap{
    color: #fff;
    margin-top: 15px;
}
.blue-page1#bluePage-wrap .pointer-wrap .outer-circle{

    border: 2px solid #ed4335;
    height: 54px;
    width: 55px;
    padding: 7px;
    display: flex;
    border-radius: 40px;
    margin-right: 14px;

}
.blue-page1#bluePage-wrap .left-side .pointer-wrap .outer-circle{
    margin-left: 14px;
    margin-right: 0px;
}
.blue-page1#bluePage-wrap .pointer-wrap .outer-circle .num{
    height: 35px;
    width: 35px;
    background: #ed4335;
    border-radius: 30px;
    text-align: center;
    line-height: 35px;
    font-size: 14px;
    font-weight: 600;
}
.blue-page1#bluePage-wrap .pointer-wrap .para h6{
    font-size: 20px;
    font-weight: 600;
    color: #121212;
}
.blue-page1#bluePage-wrap .left-side .line{
    float: right;
    margin: 0px !important;
}
.blue-page1#bluePage-wrap .left-side.mini-screen{
    display: none;
}
.blue-page1#bluePage-wrap .left-side .pointer-wrap .para p{
    margin-top: 21px;
}
.blue-page1#bluePage-wrap .pointer-wrap .para .line{
    display: block;
    height: 2px;
    width: 45px;
    background: #ed4335;
    margin: 10px 0px;
}
.blue-page1#bluePage-wrap .pointer-wrap .para p{
    color: #9fa7ad;
    font-size: 14px;
    /* height: 58px; */
}
.blue-page1#bluePage-wrap .email-img{
    height: 500px;
}

@media only screen and (min-width:300px) and (max-width:480px){
    .blue-page1#bluePage-wrap{
        height: 100%;
        overflow: auto;
        padding: 35px;
    }
    .blue-page1#bluePage-wrap .left-side.mini-screen{
        display: block !important;
    }
    .blue-page1#bluePage-wrap .left-side.mini-screen .pointer-wrap .outer-circle{
        margin-left: 0px;
        margin-right: 14px;
    }
    .blue-page1#bluePage-wrap .left-side.mini-screen .line{
        float: left;
    }
    .blue-page1#bluePage-wrap .pointer-wrap .para p{
        height: auto;
    }
    .blue-page1#bluePage-wrap .left-side.large-screen{
        display: none;
    }
    .email-img{
        display: none;
    }
}