
.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .step {
    display: flex;
    position: relative;
  }
  .step:after {
    content: "";
    position: absolute;
    left: 15px;
    top: 32px;
    height: 0;
    width: 2px;
    background-color:#9da3b5;
  }
  .step .info {
    margin: 8px 0 20px;
  }
  .step .title {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 8px;
    color: #000;
  }
  .step .text {
    font-size: 12px;
    color: #000;
    padding-bottom: 0;
    margin: 0;
  }
  .step:not(:last-child):after {
    height: 75%;
  }
  
  .number {
    width: 32px;
    height: 32px;
    background-color: transparent;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 15px;
    font-weight: 600;
    margin-right: 14px;
    background-color: #2a3042;
  }
  .number.completed {
    background-color: #2a3042;
  }
  .number svg {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
  .number svg path {
    fill: #28a745;
  }

  .spinner-border {
    display: inline-block;
    width: 20px !important;
    height: 20px !important;
    vertical-align: middle !important;
    border-width: 3px !important;
    margin-right: 0px;
}
.continue_btn {
    width: 82%;
    text-align: right;
    margin: 20px auto;
}