#graph-wrap .graph-row{
    border-bottom: 1px solid #ececec;
    margin: 30px 0px;
}
.link-btn .list-group-item{
    padding: 6px 18px;
    text-align: center;
    border: none !important;
    border-radius: 5px !important;
}
.link-btn .list-group-item.active{
    background: #5570E6 !important; 
}
.link-btn .list-group-horizontal{
    justify-content: flex-end;
}
.link-btn .list-group-item-action{
    width: auto !important;
}
#graph-wrap h5, #graph-wrap h6{
    color: #2a3042;
    font-weight: 600;
}
.link-btn.response .list-group-item{
    font-size: 12px;
}
.link-btn.response .list-group-item.active{
    background: #CDD6E6 !important;
    color: #000;
}
.link-btn.response .list-group-horizontal{
    justify-content: center;
}
.breach-wrap{
    text-align: center;
    padding: 62px;
    border-radius: 10px;
   
}
.breach-wrap h5{
    color: #565F6B !important;
    font-size: 30px;
    margin: 18px 0px;
}
.breach-wrap h6{
    color: #565F6B !important;
}
.breach-wrap p{
    color: #565F6B !important;
}