#bluePage-2-wrap{
    /* background-image: url('../../assets/world-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    background-color: #F0F0F0;
    height: 100vh;
}



#bluePage-2-wrap .main-head{

    color: #ED4335;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 3px;
    text-align: center;
    padding-top: 60px;

}
#bluePage-2-wrap .sub-head{
    color: #121212;
    font-size: 21px;
    text-align: center;
    margin-bottom: 40px;
}
#bluePage-2-wrap .mid-row{
    margin: 59px 0px;
}

#bluePage-2-wrap .mid-row .box-numeric {
    display: flex;
    align-items: center;
}
#bluePage-2-wrap .mid-row .left-unit .num-box{
    width: 82px;
    height: 82px;
    line-height: 82px;
    font-size: 34px;
    background: #D9362F;
    color: #fff;
    border-radius: 55px;
    text-align: center;
    box-shadow: 0 8px 32px rgb(217 59 50 / 32%);
}

#bluePage-2-wrap .mid-row .left-unit{
    margin-right: 32px;
}

#bluePage-2-wrap .mid-row .right-unit h4{

    font-size: 25px;
    font-weight: 600;

}
#bluePage-2-wrap .mid-row .right-unit p{
    font-size: 18px;
}

#bluePage-2-wrap .arrow-img{
    position: absolute;

}
#bluePage-2-wrap .arrow-img.arrow-1{
    left: -225px;
}
#bluePage-2-wrap .arrow-img.arrow-2{
    left: 68px;
}
#bluePage-2-wrap .arrow-img.arrow-3{
   
    left: -251px;
    height: 195px;

}
#bluePage-2-wrap .arrow-img.arrow-4{
    
    left: 30px;
    height: 167px;

}
@media only screen and (min-width:300px) and (max-width:480px){
    #bluePage-2-wrap .arrow-img.arrow-1, #bluePage-2-wrap .arrow-img.arrow-2, #bluePage-2-wrap .arrow-img.arrow-3, #bluePage-2-wrap .arrow-img.arrow-4{
        display: none
    }

    #bluePage-2-wrap .mid-row .box-numeric{
        display: block;
        margin-bottom: -50px;
        margin-top: 30px;
    }
    #bluePage-2-wrap .mid-row .left-unit .num-box{
        margin: 15px 0px;
    }
    #bluePage-2-wrap .mid-row .box-numeric:last-child{
        margin-bottom: 0px !important;
    }
}
@media (min-width: 768px){
    .order-md-2 {
        order: 2;
    }
    .order-md-1 {
        order: 1;
    }
}