.clock-wrap{

    width: 369px;
    height: 285px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

}
.clock-wrap h6{
    color: #86868a !important;
}
.clock-wrap .clock-icon{

    background: #5570E6;
    height: 60px;
    width: 60px;
    border-radius: 47px;
    padding: 13px;
    color: #fff;

}