#appsumo{
    background-image: url('../../assets/appsumo-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
}


.appsumo_warp_form {
    max-width: 430px;
    width: 100%;
    margin: auto;
    background:#fff;
    padding: 20px 20px;
    z-index: 1;
    position: relative;
    border-radius: 6px;
}
.img_wrap_logohe {
    height: 29px;
}

.btn_signin{
    text-align:center;
    padding: 15px 0px;
}
.set_sign_btn{
    text-align: center !important;
    background-color: #1056ad !important;
    color: #fff !important;
    padding:12px 10px !important;
}

.form-check-label {
    margin-bottom: 0;
    font-size: 11px;
}
.accounts_link{
    display: block;
    color: #80c1ec;
}
.terms_privacy {
    color: #80c1ec;
}

.accounts_link {
    font-size: 12px;
}
.logo_wrap_sumo {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.form-group{
    position: relative;
}
.input_wrap:focus{
    background-color: #fff;
    box-shadow: unset;
}
.label_float_css {
    position: absolute;
    color: #d3d4d5;
    top: -12px;
    left: 9px;
    font-size: 12px;
    background: white;
    padding: 3px 5px;
    z-index: 1;
}
.logo_wrap_sumo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    text-align: center;
    align-items: center;
}
.input_wrap {
    height: 45px !important;
    position: relative;
}

.img_wrap_logo {
    width: 160px;
    /* margin-right: 24px; */
    height: 45px;
    object-fit: cover;
}
.heading_sumo_form {
    color: #000;
    text-align: center;
    margin-bottom: 20px;
}


::placeholder{
    font-size: 14px;
    }
.label_check_appsumo {
    margin-bottom: 0;
    font-size: 11px;
    vertical-align: text-top;
    color: #fff;
}
.check_desc {
    font-size: 8px;
    color: #ed7c36;
    margin: -6px 0 5px;
}
.img_wrap_logo_set {
    width: 185px;
}

.input_wrap:focus .label_float_css {
    background: red !important;
}

.loader-wrap {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    text-align: center;
    background: #0000008a;
    position: fixed;
    z-index: 10000;
}
    
.loader-wrap .mid {
    margin: auto;
}

.loader-wrap i {
    color: gainsboro;
    font-size: 74px;
    margin-bottom: 10px;
}
.new_load{
    position: absolute;
    left: 50%;
    top: 50%;
}
