.policy-accordion .card{
    margin: 0px;
}

.right-arrow{
    transition: transform .25s!important;
    color: #306899;
}
.active .right-arrow{
    transition: transform .25s;
    transform: rotate( 90deg );
}
.policy-accordion .card .card-body{
    padding: 0px !important;
}

.campListGrids .card .card-header {
    background-color: #fff;
}

.campListGrids .card .btn-round{
    height: auto !important;
}

.campListGrids .tableData .table-responsive{
    padding: 0px;
}


.table-hover tbody tr:hover{
    background-color: unset !important;
}

.table-hover > tbody > tr:hover > * {
    --bs-table-accent-bg:unset !important;
    color: var(--bs-table-hover-color);
}