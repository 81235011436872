.pdf-wrap{
    background-color: #f7f9fa;
    padding: 27px;
    margin: 25px 0px;
}
.pdf-logo img{
    width: 150px;
}
.comp-logo img{
    width: 150px;   
}
.card-wrap{
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    min-height: 151px;
}
.main-head{
    text-align: center;
    margin: 37px 0px;
    font-size: 27px;
}
.row-wrap{
    background: #fff;
    border-radius: 10px;

}
td .circle{
    background: #e5e5e5;
    height: 35px;
    width: 35px;
    display: block;
    text-align: center;
    line-height: 35px;
    font-size: 20px;
    border-radius: 29px;
}
td p{
    margin-bottom: 0px;
}
.card-wrap .table{
    margin-bottom: 0px;
}
.time-wrap .timing{
    font-size: 25px;
    color: #34587c;    
}
.time-wrap .timing p{
     margin-bottom: 0px;
     font-size: 12px;
     
}
.time-wrap .dots{
    font-size: 23px;
    margin: 0px 10px;    
}
.time-wrap img{
    margin-right: 15px;
}