.invalid-wrap{
   margin-top: 50px;
}
.invalid-wrap .invalid-text{
    font-size: 100px !important;
    color: #353638;
}
.invalid-wrap .invalid-text .invalid-icon{
    font-size: 79px;
    margin-top: -21px;
    color: #556ee6;
    -webkit-font-smoothing: antialiased;
    margin-left: -21px;
}

.invalid-wrap .invalid-text .invalid-icon.animation{
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    from { transform:rotate(0deg); }
    to { transform:rotate(360deg); }
}
.invalid-wrap .text-uppercase{
    font-size: 21px;
    font-weight: 600;
    color: #4b4b4c;
    margin-top: 0px;
}
.invalid-wrap .dash-btn{
    background-color: #6172e6;
    border-color: #6172e6;
    margin-top: 40px;
    font-size: 15px !important;
}