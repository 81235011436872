#graph-wrap .graph-row{
    border-bottom: 1px solid #ececec;
    margin: 30px 0px;
}
.link-btn .list-group-item{
    padding: 6px 18px;
    text-align: center;
    border: none !important;
    border-radius: 5px !important;
}
.link-btn .list-group-item.active{
    background: #5570E6 !important; 
}
.link-btn .list-group-horizontal{
    justify-content: flex-end;
}
.link-btn .list-group-item-action{
    width: auto !important;
}