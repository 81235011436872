.input_domain{
    border:none !important;
    border-bottom: 1px solid #ccc !important;
    border-radius: 0 !important;
}
.input_domain:focus{
    outline: none !important;
    box-shadow: none !important;
}
.install_myself{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card_body{
    padding: 30px 0px;
}

.row_heading{
    border-bottom: 1px solid #ccc;
}
.row_border{
    border-bottom: 1px solid #ccc;
    padding: 20px 0px;
}
.row_border p{
    margin-bottom: 0;
}
.install_myself{
    margin-bottom: 20px;
}

.button_color{
    padding: 2px 4px !important;
    background-color: #ccc !important;
    border: 1px solid #ccc !important;
    margin-left: 10px;
}
.btn_cowrker{
    text-align: right;
}
.mail_design {
    border: 1px solid #ccc;
    padding: 50px 70px;
    width: 70%;
    /* margin: auto; */
}
.row_heading h6{
    font-weight: 600;
}
.nav-tabs {
    border-bottom: 1px solid #dee2e6 !important;
}
.btn_verify{
    text-align: right;
    margin-top: 20px;
}
.domain-btn {
    position: relative !important;
}
.copy-text-domain {
    position: absolute;
    top: -20px;
    color: #4ca746;
    right: -5px;
}

.icon_server{
    color: #306899;
    font-size: 16px !important;
}

.netwrk_solu{
    color: #306899;
}

.copy_text{
    color: #565656;
    font-size: 13px;
    cursor: pointer;
}

.copy_text_copied{
    color: #4ca746;
    font-size: 13px;
}

.yet_active{
    font-size: 14px !important;
    color: #565656;
}

.register_para_desc{
    font-size: 15px !important;
    color: #565656;
    margin-top: 10px;
}