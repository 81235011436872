.evs-logo {
    height: 100px;
    width: 100px;
    margin: 0 auto;
}

.move_top{
    position: absolute;
    top: 10px;
    right: 10px;
}

.evs-score p {
  margin-bottom: 0rem !important;
}
.right_shift{
    position:absolute !important;
    top: 0;
    right: 0;
}
.grid_table {
    position: relative;
    padding: 3px 7px;
    border: 1px solid #f2f2f2;
    border-radius: 6px;
    color: #2a3042;

    cursor: pointer;

}
.fix_right{
    background: unset !important;
    color: #666 !important;
    border: navajowhite !important;
}
.fix_right:focus{
    outline: none !important;
    box-shadow: none !important;
}
.badge-success {
    color: #fff;
    background-color: #28a745;
}

.badge-danger {
    color: #fff;
    background-color: #dc3545;
}

.badge-warning {
    color: #212529;
    background-color: #ffc107;
}

.font_style{
    font-size: 16px !important;
}

.email_phone .emai_mobile:hover {
    color: #2a3042;
}
.email_phone .emai_mobile {
    color: #666;
    text-decoration: none;
    font-size: 13px;
}

div.click-to-top span {
    display: none; 
    position: absolute;
    color: #306899;
    z-index: 1; 
}

div.click-to-top:hover span { 
    display: block; 
    inline-size: fit-content;
}

.email-ellipsis{
    margin-bottom: 0px;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.progress-C {
    width: 90px;
    height: 90px;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
  }
  .progress-C:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid #efefef;
    position: absolute;
    top: 0;
    left: 0;
  }
  .progress-C > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .progress-C .progress-C-left {
    left: 0;
  }
  .progress-C .progress-C-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 8px;
    border-style: solid;
    position: absolute;
    top: 0;
}
.progress-C .progress-C-bar.warining{
      border-color:#dd7404;

  }
  .progress-C .progress-C-bar.danger{
      border-color: #F74444;
  }
  .progress-C .progress-C-bar.success{
      border-color:#268d84;
  }
  .progress-C .progress-C-left .progress-C-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
  }
  .progress-C .progress-C-right {
    right: 0;
  }
  .progress-C .progress-C-right .progress-C-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
  }
  .progress-C .progress-C-value {
    display: flex;
    border-radius: 50%;
    font-size: 13px;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 600;
  }
  .progress-C .progress-C-value span {
    font-size: 12px;
    text-transform: uppercase;
  }
  
  /* This for loop creates the necessary css animation names 
  Due to the split circle of progress-C-left and progress-C right, we must use the animations on each side. 
  */
  .progress-C[data-percentage="1"] .progress-C-right .progress-C-bar {
    animation: loading-1 0.5s linear forwards;
  }
  .progress-C[data-percentage="1"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="2"] .progress-C-right .progress-C-bar {
    animation: loading-2 0.5s linear forwards;
  }
  .progress-C[data-percentage="2"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="3"] .progress-C-right .progress-C-bar {
    animation: loading-3 0.5s linear forwards;
  }
  .progress-C[data-percentage="3"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="4"] .progress-C-right .progress-C-bar {
    animation: loading-4 0.5s linear forwards;
  }
  .progress-C[data-percentage="4"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="5"] .progress-C-right .progress-C-bar {
    animation: loading-5 0.5s linear forwards;
  }
  .progress-C[data-percentage="5"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="6"] .progress-C-right .progress-C-bar {
    animation: loading-6 0.5s linear forwards;
  }
  .progress-C[data-percentage="6"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="7"] .progress-C-right .progress-C-bar {
    animation: loading-7 0.5s linear forwards;
  }
  .progress-C[data-percentage="7"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="8"] .progress-C-right .progress-C-bar {
    animation: loading-8 0.5s linear forwards;
  }
  .progress-C[data-percentage="8"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="9"] .progress-C-right .progress-C-bar {
    animation: loading-9 0.5s linear forwards;
  }
  .progress-C[data-percentage="9"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="10"] .progress-C-right .progress-C-bar {
    animation: loading-10 0.5s linear forwards;
  }
  .progress-C[data-percentage="10"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="11"] .progress-C-right .progress-C-bar {
    animation: loading-11 0.5s linear forwards;
  }
  .progress-C[data-percentage="11"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="12"] .progress-C-right .progress-C-bar {
    animation: loading-12 0.5s linear forwards;
  }
  .progress-C[data-percentage="12"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="13"] .progress-C-right .progress-C-bar {
    animation: loading-13 0.5s linear forwards;
  }
  .progress-C[data-percentage="13"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="14"] .progress-C-right .progress-C-bar {
    animation: loading-14 0.5s linear forwards;
  }
  .progress-C[data-percentage="14"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="15"] .progress-C-right .progress-C-bar {
    animation: loading-15 0.5s linear forwards;
  }
  .progress-C[data-percentage="15"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="16"] .progress-C-right .progress-C-bar {
    animation: loading-16 0.5s linear forwards;
  }
  .progress-C[data-percentage="16"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="17"] .progress-C-right .progress-C-bar {
    animation: loading-17 0.5s linear forwards;
  }
  .progress-C[data-percentage="17"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="18"] .progress-C-right .progress-C-bar {
    animation: loading-18 0.5s linear forwards;
  }
  .progress-C[data-percentage="18"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="19"] .progress-C-right .progress-C-bar {
    animation: loading-19 0.5s linear forwards;
  }
  .progress-C[data-percentage="19"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="20"] .progress-C-right .progress-C-bar {
    animation: loading-20 0.5s linear forwards;
  }
  .progress-C[data-percentage="20"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="21"] .progress-C-right .progress-C-bar {
    animation: loading-21 0.5s linear forwards;
  }
  .progress-C[data-percentage="21"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="22"] .progress-C-right .progress-C-bar {
    animation: loading-22 0.5s linear forwards;
  }
  .progress-C[data-percentage="22"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="23"] .progress-C-right .progress-C-bar {
    animation: loading-23 0.5s linear forwards;
  }
  .progress-C[data-percentage="23"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="24"] .progress-C-right .progress-C-bar {
    animation: loading-24 0.5s linear forwards;
  }
  .progress-C[data-percentage="24"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="25"] .progress-C-right .progress-C-bar {
    animation: loading-25 0.5s linear forwards;
  }
  .progress-C[data-percentage="25"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="26"] .progress-C-right .progress-C-bar {
    animation: loading-26 0.5s linear forwards;
  }
  .progress-C[data-percentage="26"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="27"] .progress-C-right .progress-C-bar {
    animation: loading-27 0.5s linear forwards;
  }
  .progress-C[data-percentage="27"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="28"] .progress-C-right .progress-C-bar {
    animation: loading-28 0.5s linear forwards;
  }
  .progress-C[data-percentage="28"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="29"] .progress-C-right .progress-C-bar {
    animation: loading-29 0.5s linear forwards;
  }
  .progress-C[data-percentage="29"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="30"] .progress-C-right .progress-C-bar {
    animation: loading-30 0.5s linear forwards;
  }
  .progress-C[data-percentage="30"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="31"] .progress-C-right .progress-C-bar {
    animation: loading-31 0.5s linear forwards;
  }
  .progress-C[data-percentage="31"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="32"] .progress-C-right .progress-C-bar {
    animation: loading-32 0.5s linear forwards;
  }
  .progress-C[data-percentage="32"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="33"] .progress-C-right .progress-C-bar {
    animation: loading-33 0.5s linear forwards;
  }
  .progress-C[data-percentage="33"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="34"] .progress-C-right .progress-C-bar {
    animation: loading-34 0.5s linear forwards;
  }
  .progress-C[data-percentage="34"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="35"] .progress-C-right .progress-C-bar {
    animation: loading-35 0.5s linear forwards;
  }
  .progress-C[data-percentage="35"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="36"] .progress-C-right .progress-C-bar {
    animation: loading-36 0.5s linear forwards;
  }
  .progress-C[data-percentage="36"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="37"] .progress-C-right .progress-C-bar {
    animation: loading-37 0.5s linear forwards;
  }
  .progress-C[data-percentage="37"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="38"] .progress-C-right .progress-C-bar {
    animation: loading-38 0.5s linear forwards;
  }
  .progress-C[data-percentage="38"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="39"] .progress-C-right .progress-C-bar {
    animation: loading-39 0.5s linear forwards;
  }
  .progress-C[data-percentage="39"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="40"] .progress-C-right .progress-C-bar {
    animation: loading-40 0.5s linear forwards;
  }
  .progress-C[data-percentage="40"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="41"] .progress-C-right .progress-C-bar {
    animation: loading-41 0.5s linear forwards;
  }
  .progress-C[data-percentage="41"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="42"] .progress-C-right .progress-C-bar {
    animation: loading-42 0.5s linear forwards;
  }
  .progress-C[data-percentage="42"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="43"] .progress-C-right .progress-C-bar {
    animation: loading-43 0.5s linear forwards;
  }
  .progress-C[data-percentage="43"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="44"] .progress-C-right .progress-C-bar {
    animation: loading-44 0.5s linear forwards;
  }
  .progress-C[data-percentage="44"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="45"] .progress-C-right .progress-C-bar {
    animation: loading-45 0.5s linear forwards;
  }
  .progress-C[data-percentage="45"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="46"] .progress-C-right .progress-C-bar {
    animation: loading-46 0.5s linear forwards;
  }
  .progress-C[data-percentage="46"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="47"] .progress-C-right .progress-C-bar {
    animation: loading-47 0.5s linear forwards;
  }
  .progress-C[data-percentage="47"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="48"] .progress-C-right .progress-C-bar {
    animation: loading-48 0.5s linear forwards;
  }
  .progress-C[data-percentage="48"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="49"] .progress-C-right .progress-C-bar {
    animation: loading-49 0.5s linear forwards;
  }
  .progress-C[data-percentage="49"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="50"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="50"] .progress-C-left .progress-C-bar {
    animation: 0;
  }
  
  .progress-C[data-percentage="51"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="51"] .progress-C-left .progress-C-bar {
    animation: loading-1 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="52"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="52"] .progress-C-left .progress-C-bar {
    animation: loading-2 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="53"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="53"] .progress-C-left .progress-C-bar {
    animation: loading-3 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="54"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="54"] .progress-C-left .progress-C-bar {
    animation: loading-4 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="55"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="55"] .progress-C-left .progress-C-bar {
    animation: loading-5 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="56"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="56"] .progress-C-left .progress-C-bar {
    animation: loading-6 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="57"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="57"] .progress-C-left .progress-C-bar {
    animation: loading-7 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="58"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="58"] .progress-C-left .progress-C-bar {
    animation: loading-8 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="59"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="59"] .progress-C-left .progress-C-bar {
    animation: loading-9 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="60"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="60"] .progress-C-left .progress-C-bar {
    animation: loading-10 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="61"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="61"] .progress-C-left .progress-C-bar {
    animation: loading-11 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="62"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="62"] .progress-C-left .progress-C-bar {
    animation: loading-12 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="63"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="63"] .progress-C-left .progress-C-bar {
    animation: loading-13 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="64"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="64"] .progress-C-left .progress-C-bar {
    animation: loading-14 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="65"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="65"] .progress-C-left .progress-C-bar {
    animation: loading-15 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="66"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="66"] .progress-C-left .progress-C-bar {
    animation: loading-16 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="67"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="67"] .progress-C-left .progress-C-bar {
    animation: loading-17 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="68"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="68"] .progress-C-left .progress-C-bar {
    animation: loading-18 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="69"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="69"] .progress-C-left .progress-C-bar {
    animation: loading-19 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="70"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="70"] .progress-C-left .progress-C-bar {
    animation: loading-20 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="71"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="71"] .progress-C-left .progress-C-bar {
    animation: loading-21 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="72"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="72"] .progress-C-left .progress-C-bar {
    animation: loading-22 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="73"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="73"] .progress-C-left .progress-C-bar {
    animation: loading-23 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="74"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="74"] .progress-C-left .progress-C-bar {
    animation: loading-24 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="75"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="75"] .progress-C-left .progress-C-bar {
    animation: loading-25 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="76"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="76"] .progress-C-left .progress-C-bar {
    animation: loading-26 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="77"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="77"] .progress-C-left .progress-C-bar {
    animation: loading-27 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="78"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="78"] .progress-C-left .progress-C-bar {
    animation: loading-28 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="79"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="79"] .progress-C-left .progress-C-bar {
    animation: loading-29 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="80"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="80"] .progress-C-left .progress-C-bar {
    animation: loading-30 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="81"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="81"] .progress-C-left .progress-C-bar {
    animation: loading-31 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="82"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="82"] .progress-C-left .progress-C-bar {
    animation: loading-32 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="83"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="83"] .progress-C-left .progress-C-bar {
    animation: loading-33 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="84"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="84"] .progress-C-left .progress-C-bar {
    animation: loading-34 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="85"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="85"] .progress-C-left .progress-C-bar {
    animation: loading-35 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="86"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="86"] .progress-C-left .progress-C-bar {
    animation: loading-36 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="87"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="87"] .progress-C-left .progress-C-bar {
    animation: loading-37 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="88"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="88"] .progress-C-left .progress-C-bar {
    animation: loading-38 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="89"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="89"] .progress-C-left .progress-C-bar {
    animation: loading-39 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="90"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="90"] .progress-C-left .progress-C-bar {
    animation: loading-40 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="91"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="91"] .progress-C-left .progress-C-bar {
    animation: loading-41 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="92"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="92"] .progress-C-left .progress-C-bar {
    animation: loading-42 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="93"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="93"] .progress-C-left .progress-C-bar {
    animation: loading-43 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="94"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="94"] .progress-C-left .progress-C-bar {
    animation: loading-44 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="95"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="95"] .progress-C-left .progress-C-bar {
    animation: loading-45 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="96"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="96"] .progress-C-left .progress-C-bar {
    animation: loading-46 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="97"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="97"] .progress-C-left .progress-C-bar {
    animation: loading-47 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="98"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="98"] .progress-C-left .progress-C-bar {
    animation: loading-48 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="99"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="99"] .progress-C-left .progress-C-bar {
    animation: loading-49 0.5s linear forwards 0.5s;
  }
  
  .progress-C[data-percentage="100"] .progress-C-right .progress-C-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress-C[data-percentage="100"] .progress-C-left .progress-C-bar {
    animation: loading-50 0.5s linear forwards 0.5s;
  }
  
  @keyframes loading-1 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(3.6);
      transform: rotate(3.6deg);
    }
  }
  @keyframes loading-2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(7.2);
      transform: rotate(7.2deg);
    }
  }
  @keyframes loading-3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(10.8);
      transform: rotate(10.8deg);
    }
  }
  @keyframes loading-4 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(14.4);
      transform: rotate(14.4deg);
    }
  }
  @keyframes loading-5 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(18);
      transform: rotate(18deg);
    }
  }
  @keyframes loading-6 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(21.6);
      transform: rotate(21.6deg);
    }
  }
  @keyframes loading-7 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(25.2);
      transform: rotate(25.2deg);
    }
  }
  @keyframes loading-8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(28.8);
      transform: rotate(28.8deg);
    }
  }
  @keyframes loading-9 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(32.4);
      transform: rotate(32.4deg);
    }
  }
  @keyframes loading-10 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(36);
      transform: rotate(36deg);
    }
  }
  @keyframes loading-11 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(39.6);
      transform: rotate(39.6deg);
    }
  }
  @keyframes loading-12 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(43.2);
      transform: rotate(43.2deg);
    }
  }
  @keyframes loading-13 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(46.8);
      transform: rotate(46.8deg);
    }
  }
  @keyframes loading-14 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(50.4);
      transform: rotate(50.4deg);
    }
  }
  @keyframes loading-15 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(54);
      transform: rotate(54deg);
    }
  }
  @keyframes loading-16 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(57.6);
      transform: rotate(57.6deg);
    }
  }
  @keyframes loading-17 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(61.2);
      transform: rotate(61.2deg);
    }
  }
  @keyframes loading-18 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(64.8);
      transform: rotate(64.8deg);
    }
  }
  @keyframes loading-19 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(68.4);
      transform: rotate(68.4deg);
    }
  }
  @keyframes loading-20 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(72);
      transform: rotate(72deg);
    }
  }
  @keyframes loading-21 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(75.6);
      transform: rotate(75.6deg);
    }
  }
  @keyframes loading-22 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(79.2);
      transform: rotate(79.2deg);
    }
  }
  @keyframes loading-23 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(82.8);
      transform: rotate(82.8deg);
    }
  }
  @keyframes loading-24 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(86.4);
      transform: rotate(86.4deg);
    }
  }
  @keyframes loading-25 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(90);
      transform: rotate(90deg);
    }
  }
  @keyframes loading-26 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(93.6);
      transform: rotate(93.6deg);
    }
  }
  @keyframes loading-27 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(97.2);
      transform: rotate(97.2deg);
    }
  }
  @keyframes loading-28 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(100.8);
      transform: rotate(100.8deg);
    }
  }
  @keyframes loading-29 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(104.4);
      transform: rotate(104.4deg);
    }
  }
  @keyframes loading-30 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(108);
      transform: rotate(108deg);
    }
  }
  @keyframes loading-31 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(111.6);
      transform: rotate(111.6deg);
    }
  }
  @keyframes loading-32 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(115.2);
      transform: rotate(115.2deg);
    }
  }
  @keyframes loading-33 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(118.8);
      transform: rotate(118.8deg);
    }
  }
  @keyframes loading-34 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(122.4);
      transform: rotate(122.4deg);
    }
  }
  @keyframes loading-35 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(126);
      transform: rotate(126deg);
    }
  }
  @keyframes loading-36 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(129.6);
      transform: rotate(129.6deg);
    }
  }
  @keyframes loading-37 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(133.2);
      transform: rotate(133.2deg);
    }
  }
  @keyframes loading-38 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(136.8);
      transform: rotate(136.8deg);
    }
  }
  @keyframes loading-39 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(140.4);
      transform: rotate(140.4deg);
    }
  }
  @keyframes loading-40 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(144);
      transform: rotate(144deg);
    }
  }
  @keyframes loading-41 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(147.6);
      transform: rotate(147.6deg);
    }
  }
  @keyframes loading-42 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(151.2);
      transform: rotate(151.2deg);
    }
  }
  @keyframes loading-43 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(154.8);
      transform: rotate(154.8deg);
    }
  }
  @keyframes loading-44 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(158.4);
      transform: rotate(158.4deg);
    }
  }
  @keyframes loading-45 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(162);
      transform: rotate(162deg);
    }
  }
  @keyframes loading-46 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(165.6);
      transform: rotate(165.6deg);
    }
  }
  @keyframes loading-47 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(169.2);
      transform: rotate(169.2deg);
    }
  }
  @keyframes loading-48 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(172.8);
      transform: rotate(172.8deg);
    }
  }
  @keyframes loading-49 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(176.4);
      transform: rotate(176.4deg);
    }
  }
  @keyframes loading-50 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(180);
      transform: rotate(180deg);
    }
  }

  .evsWrapper {
    display: flex;
    flex-wrap: wrap;
}
  .evsWrapper .evs-content .evs-icon{
    color: #343A40;
    background-color: #CCCECF;
    padding: 5px;
    border-radius: 5px;
    margin-right: 9px;
  }


  .evsWrapper .circleProgress{
    margin-bottom: 20px;
    padding: 10px 0px 0px;
  }

  .evsWrapper .evs-content{
    text-align: center;
    background: #fafafa;
    padding: 10px;

  }


  .evsWrapper .progress-C-value h6{
    margin-bottom: 0px;
    font-size: 11px;
  }

  .evsWrapper .progress-C-value p{
    margin-bottom: 0px;
    font-weight: bold;

  }

  .evsWrapper .evs-content h5{
    text-transform: capitalize !important;
    font-size: 13px !important;
    color: #2a3042 !important;
    font-weight: 600 !important;
    margin-bottom: 2px !important;
  }

  .evsWrapper .evs-content .evsEmail{
    font-size: 11px !important;
    color: #213a61;
    margin-bottom: 10px;
  }

  .evsWrapper .evs-content .evsNumber{
    color: #6d6d6d;
    margin-bottom: 0px;
    font-size: 10px;
  }


  .evsWrapper .esvCard{
    background: #fff;
    cursor: pointer;
    border: 1px solid #e2e5ed;
    border-radius: 5px;
    position: relative;
  }

 .evsWrapper .evsGrid{
    flex: 0 0 20.0000%;
    max-width: 20.0000%;
    padding: 0px 5px !important;
    margin-bottom: 10px;
  }

 