.create-tab .nav-item .nav-link {
  color: #2a3042;
  padding: 15px;
  border-radius: 0px !important;
  display: flex;
  align-items: center;
  font-weight: 500;
  border-bottom: 1px solid #ececec;
  font-size: 14px;
}


.create-tab .nav-item .nav-link.active {
  color: #2a3042 !important;
  background: #f3f3f3 !important;
  border-right: 3px solid rgb(25, 118, 210) !important;
}


/* .circle-steps{
  width: 25px !important;
  height: 25px !important;
  border-radius: 50%;
  position: relative;
  margin-right: 15px;
  border: 1px solid #ccc;
}

.nav-link.active .circle-steps{
  border: 1px solid #1867d2;
}

.nav-link.active .circle-steps .circle-icon{
  color: #1867d2 !important;
}

.circle-steps .circle-icon{
  color: #ccc !important;
  font-size: 40px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

/* ----------------------
    Create Campaign Template css
------------------------- */


/* right section css */
.rightSectionHeader{
  border-bottom: 1px solid #ececec;
  margin-bottom: 10px;
  padding-bottom: 12px;
}

.rightSectionHeader .tab-right-header h6{
  font-size: 15px !important;
  font-weight: 500 !important;
  margin-bottom: 4px !important;
}

.rightSectionHeader .tab-right-header p{
font-size: 12px !important;
margin-bottom: 0 !important;
color: #949494 !important;
}

.setMarginCss{
  margin-bottom: 15px;

}


/* end */


.email-template-wrap{
  height: calc(100vh - 190px);
  overflow-x: hidden;
  overflow-y: auto;
}

.footer-wrapper{
  border-top: 1px solid #ececec;
  padding: 15px;
  margin-left: 0px;
  width: 100%;
  background: #f8f8f8;
}

.navFlex{
display: flex;
}

.navFlex .navContentWrap h6{
  font-size: 13px;
  color: #000;
  margin-bottom: 2px;
}

.navFlex .navContentWrap p{
  font-size: 12px !important;
  color: #32383f !important;
  margin-bottom: 0px !important;
}

.navContentWrap{
  margin-left: 10px;
}

.navFlex .nav-status{
  width: 25px;
}


.create-tab .left {
  padding-right: 0px;
  border-right: 1px solid #ececec;
  position: relative;
}
.create-tab .tab-icon {
  margin-right: 8px;
}

/* .tabContent-block {
  padding: 20px;
} */
.tabContent-block .form-group {
  margin-bottom: 20px;
}
.tabContent-block p {
  color: #7d7c7c;
  font-size: 14px;
}
.tabContent-block .form-label {
  font-size: 15px;
  color: #323233;
}
.tabContent-block .form-check-label {
  font-size: 12px;
  color: #323233;
}
.tabContent-block .form-control {
  font-size: 14px;
  border-radius: 2px;
}

.tabContent-block .lead{
  font-size: 12px;
    margin-top: 8px;
    font-style: italic;
}

.tabContent-block .sectionTitle{
  font-size: 13px;
    font-weight: 700;
    color: #2a3042;
    border-bottom: 2px dotted #2a3042;
    padding-bottom: 10px;
}

.tabContent-block .table td{
 padding: 8px 15px !important;
}

.tabContent-block .deletebtn{
  padding: 4px;
}

.tabContent-block .domainWrap{
 display: flex !important;
 align-items: center;
 
}

.tabContent-block .tab-head {
  margin-bottom: 0px;
  font-size: 17px;
  color: #2a3042;
  font-weight: 600;
}
.tabContent-block .template-wrap .img {
  /* height: 400px; */
  width: 100%;
  object-fit: cover;
}
.tabContent-block .template-wrap .media-img {
  width: 50px;
  height: 50px;
  border-radius: 6px;
}
.tabContent-block .template-wrap .training-wrap {
  background: #e3eefe;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 10px;
}
.tabContent-block .template-wrap .training-wrap h6 {
  font-size: 12px;
}
.tabContent-block .template-wrap .training-wrap p {
  font-size: 12px;
  color: #7d7c7c;
}
.tabContent-block .btn-icon {
  font-size: 17px;
}
.tabContent-block .btn {
  display: inline-flex;
  align-items: center;
  font-size: 12px !important;
}
.tempt-img-box {
  margin-top: 20px;
  position: relative;
}

.tabContent-block .sub-head {
  color: #404042;
  justify-content: space-between;
  font-size: 14px;
}

.tempt-img-box .img-link {
  display: block;
  position: relative;
  text-decoration: none;
  margin-bottom: 10px;
}

.content-overlay {
  background: rgb(0 0 0 / 92%);
  border-radius: 9px;
}
.tempt-img-box .img-link .content-overlay .overlay-content {
  position: absolute;
  bottom: 24px;
  width: 100%;
  padding: 0px 10px;
  color: #ffff;
  opacity: 0;
  transition: all 0.4s ease-in-out 0s;
}

.tempt-img-box .img-link .content-overlay img {
  transition: all 0.4s ease-in-out 0s;
}
.tempt-img-box .img-link .content-overlay:hover img {
  opacity: 0.4;
}
.tempt-img-box .img-link .content-overlay:hover .overlay-content {
  opacity: 1;
}

.tempt-img-box .img-link img {
  width: 100%;
}
.tempt-img-box .launch-icon {
  font-size: 13px !important;
  margin-top: -2px;
}
.tempt-img-box .temp-head {
  margin: 0px !important;
  font-size: 15px;
  color: #2a3042;
  width: 277px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.r-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.form-control {
  -webkit-appearance: auto !important;
  appearance: auto !important;
}
.btn-dots {
  background-color: transparent !important;
  color: #2a3042 !important;
  border: none !important;
}
.head-2 .go2646822163 {
  height: 10px;
  border: none;
  /* border: 1px solid #b0b3b5; */
  border-radius: 0px !important;
  background: transparent;
  font-size: 14px;
}
.head-2 .go2139093995 .gray {
  color: #495057;
}
.check-icon {
  color: #4ca746;

}
.head-2 .go2139093995 .dropdown-content {
  position: relative;
  z-index: 10000;
  background-color: white;
}
.tabContent-block .note-box {
  background: rgb(74 167 70 / 14%);
  padding: 20px;
  margin: 5px;
  margin-bottom: 40px;
  border-radius: 10px;
}
.tabContent-block .note-box h5 {
  font-weight: 600;
}

.tabContent-block .p-badge {
  background: #d5eefd;
  width: fit-content;
  text-align: center;
  border-radius: 15px;
  padding: 3px 25px;
}
.tempalte-nav-tab .nav-link.disabled {
  pointer-events: all;
  cursor: not-allowed;
  color: #6c757d !important;
}
.landing-red {
  text-decoration: none !important;
}
.set_width {
  /* max-width: 18px !important; */
  text-align: center;
}

.set_width p {
  margin-top: 5px;
}

.badgeRow{
  display: flex;
    margin: 10px -15px;
    flex-wrap: wrap;
}
.badgeRow .badge{
 margin-right: 5px;
 margin-bottom: 10px;
}

.badgeRow .btn-icon{
  margin-right: 0;
}

.contentRgt{
  padding: 15px 30px 0px 15px !important;

}



.tableContainerWrapInner .setbgColor:last-child{
  background-color: white !important;
}

.email-template-wrap .attackCard .widthSet{
width: 50% !important;
}
.email-template-wrap .attackCard{
  margin-bottom: 20px !important;
}


.domainWrap .form-group{
  width: 40%;
}

.email-template-wrap .midCard{
background-color: #fafafa;
padding: 15px !important;
}

.email-template-wrap .midCard .dFlex{
display: flex;
margin-right: 15px;
align-items: center;
margin-bottom: 0px;
}


.email-template-wrap .midCard .dFlex .form-label{
  width: 13%;
}

.email-template-wrap .midCard .dFlex .form-control{
  width: 50%;
}

.email-template-wrap .midCard .dFlex .form-check-input{
  margin-right: 10px;
}

.email-template-wrap .midCard .radioCheck .form-group{
display: flex !important;
margin-bottom: 8px !important;
align-items: center !important;
}

.email-template-wrap .midCard .radioCheck .form-check-input{
  position: absolute !important;
}


.email-template-wrap .midCard .radioCheck .form-check-label{
  margin-left: 24px !important;
  margin-bottom: -4px !important;
}


.email-template-wrap .midCard .headerPara{
  margin-bottom: 12px;
}

.email-template-wrap .attackCard .midCard h5{
margin-bottom: 4px !important;
font-size: 15px !important;
}

.email-template-wrap .attackCard .midCard p{
font-size: 12px !important;
margin-bottom: 0px;
}

.warningCard{
color: #856404;
padding: 15px !important;
background-color: #fff3cd;
border: 1px solid #ffeeba;

}

.warningCard ul{
    margin-bottom: 0px !important;
}

.warningCard ul li{
    font-size: 13px;
    margin-bottom: 2px;
}

.warningCard h5{
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 5px;
}

.setbtnPadding{
padding:20px 0px !important;
}


.attackheader h5{
  margin-bottom: 4px !important;
  font-size: 13px !important;
}
