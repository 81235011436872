.product-modal .modal-dialog{ 
max-width: 820px;
margin-top: 95px;
}
.product-modal .modal-body{
    padding: 25px;
    padding-top: 45px;
}
.product-modal .modal-header{
    border: none;
    background-color: #fff !important;
}
.product-modal .main-heading{
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
}
.product-modal .u-line{
    width: 63px;
    height: 4px;
    background: black;
    display: block;
    margin: 0 auto;
    border-radius: 3px;
}

.product-modal .first-para{
    font-size: 13px;
    text-align: center;
    margin: 23px 0px;
}
.product-modal .welcome-points{
    list-style: none;
}
.product-modal .welcome-points li{
    line-height: 37px;
}
.product-modal .welcome-points .list-icon{
    font-size: 20px;
}
.product-modal .round-btn{
    border: none;
    border-radius: 19px;
    padding: 9px 25px 11px;
    font-size: 15px;
    font-weight:600;
}

.product-modal .dark-blue{

    background: #2B3A52;
    color: #fff;

}   
.product-modal .light-blue{
    background: #D1E0F2;
    color: #2b3a52;
}
.product-modal .guide-img{

    width: 52%;

}
.product-modal .fourth-wrap .guide-img{
    width: 40%;
}
.first-wrap, .second-wrap, .third-wrap, .fourth-wrap, .fifth-wrap, .sixth-wrap{
    transition: opacity 0.15s linear;
}
.product-modal .skip-btn{

    position: absolute;
    right: 0px;
    border: none;
    background: #fff;
    font-size: 13px;
    padding: 10px;
    color: #808386;
    top: 10px;

}