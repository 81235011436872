.templateDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.templateDiv p {
  margin-bottom: 0;
  font-size: 14px;
}

.radioBtnFlex {
  display: flex;
  padding: 0px 5px;
}
.form_check {
  margin-right: 15px;
}
.tabClass .tab-content {
  max-height: 64vh;
  overflow: auto;
}
.arrowIcon {
  margin-right: 5px;
  font-size: 18px !important;
}
.footer_template {
  border-top: 1px solid #e7e7e7;
  padding-top: 10px;
  position: absolute;
  bottom: 0;
  width: 86%;
  background: #fff;
  /* position: absolute;
  bottom: 0;
  width: 100%;
  left: 0; */
}
.cardCss {
  margin: 10px 0px !important;
}
.cardCss .card-body {
  border: 1px solid #f7f7f7;
  border-top: none;
  padding: 13px !important;
}
.cardCss .btn-link {
  text-decoration: none;
  color: #000;
  width: 100% !important;
}
.cardCss .btn-link:hover {
  text-decoration: none;
  color: #000;
}
.cardCss .card-header {
  padding: 3px;
  border-bottom: none;
}
.footer_template_gene {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #e7e7e7;
  padding-top: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #fff;
}
/* .tab-content {
  height: 70vh;
  position: relative;
} */
.Aitemplate-header img {
  height: 60px;
  object-fit: contain;
  width: auto !important;
}
.ai-block {
  height: 84vh;
}
.template_wraper {
  border: 1px solid #f6f6f6;
  overflow-y: auto;
  height: 490px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 4px #d6d6d6;
}

.template_box label {
  font-size: 13px;
  margin-bottom: 4px;
}
.Aitemplate-header {
  text-align: left;
}

.form-control-file {
  font-size: 12px;
}

.template_box {
  position: relative;
  height: 80vh;
}
.info_generate {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 68vh;
}

.checkbox {
  margin-bottom: 0 !important;
  margin-right: 15px !important;
  display: flex;
}
.tabClass .nav-tabs .nav-link {
  color: #2f52b0;
  font-size: 14px;
  background: #f3f3f3;
  border-radius: 0 !important;
  display: flex;
  align-items: center;
}
.tabClass .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #315aaa;
  border-color: #dee2e6 #dee2e6 #fff;
  display: flex;
  align-items: center;
  font-size: 13px;
}
.tabClass .nav-tabs .nav-link.active {
  border: 0 !important;
}
.tabClass .nav-tabs .nav-link.disabled {
  background: #f3f3f3;
  cursor: no-drop;
  pointer-events: all;
}

.tabClass .nav-tabs .nav-link.disabled span {
  color: #717a83;
  background-color: #dcdcdc;
}

.tabClass .nav-tabs .nav-link.active .circle_badge.badge-light {
  color: #2f52b0;
  background-color: #ffffff;
}
.tabClass {
  border-right: 1px solid #f6f6f6;
}
.tabClass .nav-tabs {
  border-bottom: unset !important;
}
.circle_badge.badge-light {
  color: #ffffff;
  background-color: #2f52b0;
}
.circle_badge {
  border-radius: 100% !important;
  margin-right: 5px;
  padding: 4px 6px !important;
}
.textAlignWrap{
  direction: rtl;
  text-align: right;

}
