.preview-modal .modal-dialog{
    max-width: 1000px;
}
.preview-modal .modal-cancel{
    position: absolute;
    right: 10px;
    top: 10px;
    background: #e8e6e6;
    cursor: pointer;
    z-index: 10;
}