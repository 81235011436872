#test-result .body-row{
    border:1px solid #f8f8f8;
    text-align: unset;
}
#test-result .body-row:hover {
    background-color: inherit;
    cursor: auto;
}

#test-result button{
    border:none;
    /* background-color: #fff; */
}
#test-result button:focus{
    outline: none;
}

#test-result .timeline {
    min-height: 0px !important;
}
#test-result ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
#test-result ul.timeline > li {
    margin: 20px 0;
    padding-left: 35px;
}
#test-result ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}

#test-result .test-result-wrap.accordion .collapse.show{
    border-left: 2.5px solid #306899;
    background: #f6f6f6;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    border-radius: 0px 0px 5px 5px;
    border: 1px solid #e2e5ed;
    padding: 10px 20px;
}

#test-result .test-result-wrap .card-body{
    padding: 0px 25px !important;
}
#test-result .test-result-wrap .activities-history-item .accordion2 .card{
    margin: 10px 0px 0px 0px;
}
#test-result .test-result-wrap .activities-history-item .accordion2 .card-body{
    background: #fff;
    padding-top: 10px !important;
}
#test-result .test-result-wrap .activities-history-item .accordion2 .card-body p{
    margin-bottom: 10px;
    font-size: 14px;
}

#test-result .test-result-wrap .activities-history-item h6{
    font-size: 14px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.testAccordian .activity-head{
    font-size: 14px;
    color: #303840;
    align-items: center;
    font-weight: 600;
    margin-bottom: 0;
}