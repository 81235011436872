

.hackWrapper{
    width: 35%;
    background: #e9ecef69;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
}


.hackWrapper .cardHeding h6{
    font-size: 14px;
    font-weight: 600;
   }
.hackWrapper .cardHeding p{
 font-size: 13px;
 color: #838383;
}

.hackWrapper .btn_search{
    margin-left: 10px;
    border-radius: 5px !important;
    padding: 0px 10px !important;
}

.hackWrapper h5{
    color: #d30d0d;
    margin-top: 15px;
    font-size: 13px;
    margin-bottom: 0;
}