#main-body {
    background: url(../../assets/background.jpg);
    background-size: cover;
    min-height: 100vh;
    padding: 30px;
    padding-top: 50px;
}
.heading-type {
    text-align: center;
    color: #D24231;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 16px;
}
.logo-awareness {
    position: absolute;
    left: 30px;
    width: 121px;
    background: #fff;
    height: 57px;
    padding: 7px;
    object-fit: contain;
}
.head-para {
    text-align: center;
    color: white;
    font-size: 19px;
}
.mid-para {
    text-align: center;
    color: white;
    font-weight: 300;
}
.middle-side {
    padding-top: 15px;
}
.middle-side .image-header {
    background: #fff;
    box-shadow: 7px 11px 19px #0c0c0c;
}
.middle-side .image-header .head {
    background: #faf9f7;
    padding: 15px;
    border-bottom: 1px solid #d9d9d9;
    font-size: 17px;
    color: #242321;

}
.middle-side .image-header .fav-logo {
    max-width: 11.333333%;
}
.middle-side .image-header .mail-head {
    font-size: 15px;
    color: #212f3a;
    font-weight: 600;
    margin-bottom: 2px;
}
.middle-side .image-header .mail-head span{
    font-weight: 400;
}
.middle-side .image-header .date {
    font-size: 12px;
    color: #8c8a88;
    margin-bottom: 0px;
}
.middle-side .image-header .date {
    font-size: 12px;
    color: #8c8a88;
    margin-bottom: 0px;
}
.content-block {
    margin: 50px 0px;
    position: relative;
}
.content-block h4 {
    color: #2F90E7;
    font-weight: 300;
    font-size: 22px;
}
.content-block p {
    color: white;
    font-weight: 200;
    font-size: 14px;
}
.mail-icon{
    height: 44px;
}
.main-temp{
    border: 4px solid #2196f3;
    margin-top: 15px;
    height: 575px;
}
.main-temp img{
    height: 566px;

}
.from-arrow{
    position: absolute;
    height: 164px;
    left: 48%;
    z-index: 1;
    top: 27px;

}
.from-block{
    padding-top: 48px;
}
.contentBlock{
    padding-top: 115px;
}
.content-arrow{
    position: absolute;
    height: 120px;
    left: 68%;
    z-index: 10;
    top: 43%;
}
.subject-arrow{

    position: absolute;
    height: 56px;
    left: -7%;
    top: 14px;

}
.hyperlink-arrow{
    position: absolute;
    top: 65%;
    right: 89%;
    height: 47px;
}
.hyperlink-block{
    padding-top: 266px;
}