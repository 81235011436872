.header{
    height: 64px;
    padding: 5px 25px 5px 10px;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    transition: all 0.3s ease;
    padding: 8px 15px;
    position: fixed;
}
.fixed-top{
    left: 250px !important;
}

.bg-light.header {
    background-color: #ffffff !important;
}
.mini-sidebar {
    cursor: pointer;
    margin-right: 12px;
}
.mini-sidebar svg {
    color:#fff;
}
.header .user-dropdown .btn-primary{
    color: #646569 !important;
    align-items: center;
    text-transform: capitalize;
    border: 1px solid #dadce0 !important;
    border-radius: 10px !important;
    background: #fbfbfb !important;
    display: flex;
    padding: 5px 8px !important;
}
.header .show > .btn-primary.dropdown-toggle{
    background: #fff;
    border: 1px solid #d6d5d5;
    color: #646569;
}
.header .show > .btn-primary.dropdown-toggle:focus{
    box-shadow: none !important;
}
.header .btn-primary:focus, .header .btn-primary:active:focus{
    box-shadow: none !important;

}
.header .btn-primary:active{
    color: #222223 !important;
    background-color: transparent !important;
    border-color:rgb(0 0 0 / 30%) !important;
}
.header .user-dropdown .btn-primary .user-icon{
    height: 32px;
    width: 32px;
    background: blue;
    border-radius: 50px;
    display: flex;
    font-size: 22px;
    align-items: center;
    color: #fff;
    z-index: 10;
    margin: 0px -12px 0px 0px;
    margin-top: 0px;
    display: block;
}


.LmsRightBtn .leftcompImg{
    max-height: 32px !important;
}

.header .user-dropdown .comp-dropdown .comp-logo{
    max-height: 35px;
    width: 100px;
    margin-right: 7px;
    vertical-align: middle;
    object-fit: contain;
    text-align: center;
}
.header .user-dropdown .dropdown-menu{
    margin-left: -20px !important;
    margin-top: 13px !important;
}

.header .user-dropdown .dropdown-menu a{
font-size: 13px;
padding: 0px 10px;
}


.RightLogin .dropdown-header{
    text-align: center;
    padding: 0px;
    color: #263a5b;
}

.header .user-dropdown .dropdown-menu::before{
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-top: 1px solid #d6d5d5;
    border-left: 1px solid #d6d5d5;
    right: 16px;
    top: -10px;
    background: #fff;
    transform: rotate(45deg);
}
.header .add-btn{
    font-size: 15px;
    padding: 4px 6px;
}

.header1 .dropdown-menu {
    left: -75px!important;
}

.headerlanguage a {
    display: none!important; 
}

.header .setting-btn{
    background: none;
    border:none;
    color: #2a3042;
}
.header .setting-btn .btn-primary:hover{
    background-color: none !important;
    border:none;
    color: #67686d;
}

.MidLanguage{
    margin-right: 10px;
}

/* .header .Midinfobar{
    border-radius: 100%;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    background: #f8f9fc !important;
    border: 1px solid #ced4da !important;
} */

.header .Midinfobar{
    margin-right: 10px !important;
}

.header .Midinfobar .round-btn {
    background: #f8f9fc !important;
    color: #000 !important;
    padding: 4px 7px 5px 7px !important;
    border-radius: 51% !important;
    border: 1px solid #ced4da !important;
    font-size: 11px !important;
}



.header .round-btn{
    font-size: 11px !important;
    background-color:unset !important;
    border-color: unset !important;  
}

.header .round-btn:hover{
    background: #f8f9fc !important;

}


.header .round-btn svg{
    color: #000 !important;
}

.header .CreatCambtn svg{
    color: #59c68c;
}


.header .head-link{
    color: #2a3042;
    text-decoration: none;
    margin-left: 10px;
}
.header .head-link .link-icon{
    font-size: 21px;
    margin-top: -2px;
}
.header .head-link:hover {
    color: #0a58ca;
}



.LmsRightBtn .comp-logo{
width: 32px !important;
padding-right: 0px !important;
}

.LmsRightBtn .dropdown-toggle::after{
display: none !important;
}
/* 
.LmsRightBtn button{
    border: 1px solid #dadce0;
    border-radius: 10px;
    background: #fbfbfb;
    display: block;
    padding:5px 8px;
} */

.LmsRightBtn .dropdown-menu::before {
    left: 28px !important;
}

.LmsRightBtn{
    margin-right: 10px;
}

.LmsRightBtn .LmsLogobtnCont{
    font-size: 11px;
    margin-left: 0px;
    margin-right: 0px;
    color: black;
}

