.my-load {
    width: 100%;
    position: fixed;
    z-index: 100000;
    background: #0000007d;
    height: 100%;
    display: flex;
    align-items: center;
    top: 0px;
    left: 0px;
}

.loader-demo-box {
    border: none !important;
    display: flex;
    align-items: center;
}

.loader-demo-box {
    width: 100%;
    height: 200px;
}

.bar-loader {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
}

.bar-loader span:nth-child(1) {
    animation: grow 1s ease-in-out infinite;
}

.bar-loader span:nth-child(2) {
    animation: grow 1s ease-in-out 0.15s infinite;
}

.bar-loader span:nth-child(3) {
    animation: grow 1s ease-in-out 0.30s infinite;
}

.bar-loader span:nth-child(4) {
    animation: grow 1s ease-in-out 0.45s infinite;
}

.bar-loader span {
    display: inline-block;
    width: 5px;
    height: 30px;
    background-color: rgba(255, 71, 71, 0.7);
    margin: 0px 2px;
}

@keyframes grow {
    0%,
    100% {
        -webkit-transform: scaleY(1);
        -ms-transform: scaleY(1);
        -o-transform: scaleY(1);
        transform: scaleY(1);
    }
    50% {
        -webkit-transform: scaleY(1.8);
        -ms-transform: scaleY(1.8);
        -o-transform: scaleY(1.8);
        transform: scaleY(1.8);
    }
}

.component-loader,
.component-loader:before,
.component-loader:after {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
}

.component-loader {
    color: #2a3042a8;
    font-size: 10px;
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.component-loader:before,
.component-loader:after {
    content: '';
    position: absolute;
    top: 0;
}

.component-loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.component-loader:after {
    left: 3.5em;
}

@-webkit-keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

@keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}