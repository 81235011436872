#accordion-block .accordion .card{
    margin: 0px;
    box-shadow: 0 3px 6px 0 rgba(41,70,97,.15);
    margin-bottom: 60px;
}
#accordion-block .accordion .card h5{
    margin-bottom: 0px;
    background: rgb(42 48 66 / 10%);
    padding: 15px;
    display: flex;
    align-items: center;
}
#accordion-block .accordion .card h5 b{
    margin-right: 7px;
}
#accordion-block .accordion .card .card-header{
    background: rgb(234 234 236 / 44%);
    padding: 15px;
    color: #434448;
    cursor: pointer;
}
#accordion-block .accordion .card h5 .accord-icon{
    color: #4aa746;
    font-size: 22px;
    margin-right: 7px;
}
#accordion-block .accordion .right-arrow{
    float: right;
}