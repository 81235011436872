#tab-wrapper .tab-content{
    border: unset !important;
    padding:15px;
}

/*******************************
TAB
********************************/

#tab-wrapper .tab-wrap .upload-block{
    width: 230px;
    height: 200px;
    display: flex;
    align-items: center;
    border: 2px dashed #dedcdc;
}
#tab-wrapper .tab-wrap .upload-block .upload-btn{
    z-index: 10;
}
#tab-wrapper .tab-wrap .upload-img{
    position: absolute;
    top: 65px;
    z-index: 1;
    width: 202px;
    height: 187px;
    left: 15px;
    /* right: 0px; */
    /* margin: 0 auto; */
    object-fit: contain;
}
.setting-body{
    text-align: center;
    padding: 10px 0px;
    border-bottom: 1px solid #e5e8ea;
    font-size: 14px;
}
.txtModal .modal-dialog{
    max-width: 600px;
}
.txtModal .modal-header h6{
    font-size: 19px;
    color: #ffff;
    font-weight: 600;
}
.txtModal .modal-header p{
    margin-bottom: 0px;
    font-size: 15px;
    color: #e0e0e0;
}
.txtModal .modal-header{
    background: #2a3042;
}
.txtModal .modal-body ul{
    list-style: decimal;
    padding-left: 17px;
    line-height: 35px;
    font-size: 15px;
    color: #4a4a4c;
}
.txtModal .modal-body .form-control{
    color: #8a8c90 !important;
    border: none;
    border-bottom: 1px solid #c5c6c7;
    border-radius: 0px !important;
    padding-left: 0px;
}
.txtModal .modal-body p{
    color: #636467;
    font-size: 15px;
}
.txtModal .modal-header .close{
    color: #fff;
}
.txtModal .modal-body{
    margin: 18px 5px;
}
.txtModal .modal-body select.form-control{
    border: none;
    background: rgb(248 249 250);
    border-radius: 4px !important;
    color: #2a3042 !important;
    padding: 5px;
}
.input-group-append .btn{
    border-radius: 0px !important;
}
.txtModal .modal-body .http-wrap .count{
    background: #f4f4f5;
    width: 25px;
    height: 25px;
    text-align: center;
    border-radius: 23px;
    font-weight: 600;
    margin-right: 10px;
    font-size: 13px;
    line-height: 24px;
    color: #2a3042;

}
.txtModal .modal-body .http-wrap ul{
    color: #087afc;
    list-style: disc;
}
.txtModal .modal-body .copy-text{
    color: green;
    position: absolute;
    right: -2px;
    bottom: 16px;
}
.txtModal .modal-body .copy-text2{
    color: green;
    position: absolute;
    right: -2px;
    bottom: 25px;

}
.view-btn{
    border-radius: 23px !important;
    color: #4ca746 !important;
    background-color: #4ca74638 !important;
    border-color: transparent !important;
    padding: 3px 15px !important;
}

.note-box1 {
    background: rgb(74 167 70/14%);
    padding: 20px;
    border-radius: 10px !important;
}



/*******************************
TAB Box
********************************/

.tab-setting .tab-wrap .tabHeading{
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 15px;
    margin-bottom: 6px;
    margin-top: 5px;
}

.tab-setting .tab-wrap .tabHeading h6{
    font-size: 14px !important;
    margin-bottom: 5px;
    font-weight: 600 !important;
}

.tab-setting .tab-wrap .tabHeading p{
    font-size: 12px !important;
    color: #8e8e8e !important;
    margin-bottom: 0px;
}

.tab-setting .tab-wrap .profile-form{
margin-bottom: 20px;
}

.tab-setting .profile-form .deleteBtn{
    background: unset !important;
    color: blue !important;
    padding: 0px !important;
}

.tooltip-icon {
    margin-left: 7px !important;
    margin-bottom: 0px;
    color: #0c0b0b !important;
    background: #e2e5ed;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    padding: 2px;
    cursor: pointer;
}

.noteSection{
    margin-bottom: 10px;
    margin-top: 10px;
}

.noteSection p{
    color: #856404;
    background-color: #fef9ec;
    margin-bottom: 0px;
    font-size: 11px;
    border: 2px solid #ffeeba;
    padding: 6px 8px;
    border-radius: 5px;
}

.authenticCard{
    background: #fff;
    border: 1px solid #e2e5ed;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
}

.authenticCard .dFlex{
    display: flex;
    align-items: center;
    border-bottom: 2px solid #f1f1f1;
    padding-bottom: 10px;
    margin-bottom: 5px;
}

.authenticCard .dFlex .leftIcon{
    background: #e9ecef69;
    padding: 15px 2px;
    border-radius: 5px;
    margin: 0 auto;
    text-align: center;
}

.authenticCard .dFlex .leftIcon img{
width: 55%;
}

.authenticCard .dFlex .rightContent h6{
    font-size: 13px;
}

.rightContent{
    margin-left: 20px;
}

.authenticCard .switchBages{
    font-size: 10px;
    padding: 2px 7px;
    border-radius: 3px;
    margin-left: 7px;
}

.authenticCard .dFlex .rightContent .disable{
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.authenticCard .dFlex .rightContent .enableBages{
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.authenticCard .dFlex .rightContent p{
    font-size: 11px;
    margin-bottom: 0px;
    color: #8e8e8e !important;
}

.enableToggle{
    justify-content: space-between;
    display: flex;
    margin-top: 10px;
    align-items: center;
}


.enableToggle h6{
    font-size: 10px;
    color: #8e8e8e !important;
    margin-bottom: 0px;
}

.enableToggle .custom-control-label::before{
    height: 13px !important;
}

.enableToggle .custom-switch .custom-control-label::after{
    height: calc(11px - 2px) !important;
    width: calc(10px - 2px) !important;
}

.tab-setting .badge{
    font-size: 10px !important;
    padding: 5px 8px !important;
    text-transform: capitalize !important; 
}

.tab-setting .compHeading{
    margin-bottom: 15px;
    font-size: 13px;
    color: #303840;
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-top: 12px;
}

.tab-setting .compHeading .point {
    font-size: 9px;
    color: #fff;
    padding: 4px 8px;
    border-radius: 15px;
    margin-right: 10px;
    font-weight: 600;
    background: #303840;
}

.tab-setting .form-check{
    display: flex;
    align-items: center;
}

.tab-setting .form-check-input{
    margin-top: 0px;
    margin-left: 10px;
}

.tab-setting .form-check-label{
    margin-left: 10px;
}


.tab-setting .nav-tabs {
    border-top: unset !important;
    padding: 0 15px;
}

.tab-setting .nav-tabs .nav-link{
    font-weight: 500 !important;
}

.tab-setting .gsuiteTable tr th{
    text-align: center;
    font-weight: 600;
    background: #f6f6f6;
    font-size: 13px;
}
.tab-setting .upload-block{
    margin: 20px 0px !important;
}


.tab-setting .formFile .form-file{
width: 100% !important;
}