#click1, #click2, #click3{
    /* width: 650px; */
    padding: 20px;
}
.mid-text .location{
    color: #5570E6;
    font-size: 50px;
    margin: 24px 0px;
}
.top-countries .progress{
    height: 5px;
}
.Count p{
    margin-bottom: 0px;
    color: #737477;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    width: 75px;
}

.map-selected {
    fill: #E3DA37;
}

.map-unselected {
    fill: #699EAA;
}

.map-selected:hover, .map-unselected:hover {
    cursor: pointer;
}
.click-block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ececec;
    padding: 25px;
    border-radius: 14px;
}
.click-block .click-rate h5{
    font-size: 16px;
}
.click-block .click-rate h1{

}

.click-block .total-img{
    height: 70px;
    width: 70px;
    background: #6fc1fd21;
    padding: 15px;
    border-radius: 10px;
}
.click-block .avg-img{
    
    height: 70px;
    width: 70px;
    background: #f5a2d821;
    padding: 15px;
    border-radius: 10px;
}
.click-block .country-img{
    height: 70px;
    width: 70px;
    background: #fdb25f21;
    padding: 15px;
    border-radius: 10px;
}