
.card-body{
    padding:0px;
}

.multi-select-categories{
    border-top: unset !important;
    border-bottom: unset !important;
    border-left: unset;
    border-right: unset;
    padding:unset;
    font-size: 13px !important;
    font-weight: 300;
    color: #495057;
}

.multi-select-categories .dropdown-content{
    width: 240px;
    background-color: white;
    padding: 10px !important;
    box-shadow: unset !important;
    z-index: 9999;
}

.multi-select-categories  .select-panel{
padding: 10px;
}

.item-renderer span{
font-size: 12px;
}


.camp-template-card {
    background-color: transparent !important;
    margin: 0px !important;
}

.btn {
    font-size: 13px !important;
}


/*******************************
Campaign Templates Css
********************************/
.tempCard{
    padding: 5px;
}

.Campaign-Card-Box{
    position: relative !important;
    display: block;
    text-decoration: none;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, .11), 0 0 1px 0 rgba(0, 0, 0, .1);
    border: 1px solid #e0dede;
    border-radius: 9px;
    padding: 2px;
}

.Campaign-Card-Box .Card-img-link .topBadge{
    position: absolute;
    background: rgb(0 0 0 / 46%);
    font-size: 10px;
    font-weight: 400;
    padding: 5px 10px;
    left: 10px;
    top: 8px;
    border: 1.8px solid #e1e1e1;
} 

.Campaign-Card-Box .Card-img-link .cardImg img{
    object-fit: cover;
    height: 200px !important;
    width: 100%;
    border-bottom: 1px solid #e1e5ed;
}

.Campaign-Card-Box .Card-img-link .cardImg{
    height: 200px !important;
}

.cardBody{
    display: flex;
    padding: 8px 10px;
    justify-content: space-between;
    border-bottom: 1px solid #e2e5ed;
}


.cardBody .titleName h2{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
}
.addCursor{
    cursor: pointer;
}
.cardBody .titleName h6{
    font-size: 10px;
    color: #9c9c9c;
    margin-bottom: 0px;
}

.dropdownIcon .dot-icon{
    font-size: 20px !important;
    color: #167af6;
    padding: 0px !important;
}

.dropdownIcon .btn-only{
    padding: 0px !important;
}

.cardfooterBox .badge{
    padding: 6px 10px;
    font-weight: 400;
    border-radius: 3px;
    font-size: 10px;
    margin-right: 8px;
    text-transform: uppercase;
}

.cardfooterBox .badgeOne{
    color: #1967d2;
    background: #d7e7ff;
}
.cardfooterBox .badgeTwo{
    background: #e9ecef;
    color: rgba(39, 44, 51, .7);
}

.cardfooterBox{
    padding: 8px 9px;
}

.cardFooter .dropdownIcon .dropdown-item{
    font-size: 12px;
    padding: 4px 16px;
}


/*******************************
Campaign Templates Css End
********************************/


.dot-icon {
    color: #7280ab;
    font-size: 30px !important;
}

.dropdown-icon {
    position: absolute !important;
    z-index: 1;
    right: 3px;
    top: 5px;
}

.btn-only {
    background-color: transparent !important;
    border: none !important;
    padding: 0px !important;
}

.camp-template-card .form-control {
    background-color: transparent !important;
    font-size: 14px;
    border: 1px solid #b0b3b5 !important;

}

.tempt-img-box .img-link {
    display: block;
    position: relative;
    text-decoration: none;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, .11), 0 0 1px 0 rgba(0, 0, 0, .1);
    border: 1px solid #e0dede;
    border-radius: 9px;
    padding: 4px;

}

/* .content-overlay {
    background: rgb(0 0 0 / 100%);
    border-radius: 9px;
    min-height: 240px;
} */

.tempt-img-box .img-link .content-overlay .overlay-content {
    position: absolute;
    bottom: 0px;
    height: 100%;
    width: 100%;
    padding: 0px 10px;
    color: #ffff;
    opacity: 0;
    transition: all 0.4s ease-in-out 0s;
}

.tempt-img-box .img-link .content-overlay .overlay-content ul {
    list-style: none;
    padding-left: 10px;
    color: #fff;
    margin-bottom: 0px;
    margin-top: 25px;
}

.tempt-img-box .img-link .content-overlay .overlay-content ul li {

    margin-bottom: 6px;
    font-weight: 600;
    display: flex;
    align-items: center;

}

.tempt-img-box .img-link .content-overlay .overlay-content ul li:last-child {
    position: absolute;
    right: 19px;
    bottom: 13px;
}


.tempt-img-box .img-link .content-overlay .overlay-content label {
    font-size: 16px;
}

.tempt-img-box .img-link .content-overlay .text {
    visibility: hidden;
}

.tempt-img-box .img-link .content-overlay img {
    transition: all 0.4s ease-in-out 0s;
}

.tempt-img-box .img-link .content-overlay:hover img {
    opacity: .4;
}

.tempt-img-box .img-link .content-overlay:hover .overlay-content {
    opacity: 1;
}

.tempt-img-box .img-link img {
    width: 100%;
    height: 240px;
    object-fit: cover;
}

.tempt-img-box .launch-icon {
    font-size: 13px !important;
    margin-top: -2px;
}
.temp-head-1{
    margin: 0px !important;
    margin-left: 5px!important;
    font-size: 13px;
    color: #2a3042;
    width: 277px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.toggleTraining{
    display: flex !important;
}

.tempt-img-box .temp-head {
    margin: 0px !important;
    font-size: 13px;
    color: #2a3042;
    width: 277px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.custom-switch {
    padding-left: 2.25rem !important;
}

.custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}


input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}


.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-control-label:before,
.custom-file-label,
.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-control-label:after,
.custom-control-label:before {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
}

.custom-control-label:before {
    pointer-events: none;
    background-color: #fff;
    border: 1px solid #adb5bd;
}

.custom-switch .custom-control-label:before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: .5rem;
}

.custom-switch .custom-control-label:after {
    top: calc(.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: .5rem;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-control-label:after {
    background: no-repeat 50%/50% 50%;
}

.custom-camp-table .table-head {
    padding: 11px;
    color: #2a3042;
    font-weight: 600;
    font-size: 15px;
}

.custom-camp-table p {
    font-size: 12px;
    margin-bottom: 0px;
}

.custom-camp-table .table-body {
    display: flex;
    align-items: center;
    padding: 12px;
    font-size: 15px;
    cursor: pointer;
}

.custom-camp-table .table-body:last-child {
    margin-bottom: 10px !important;
}

.custom-camp-table .table-body:hover {
    color: #82899f;
}

.overlay-content .yes-badge {
    background: #41BEA7;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 22px;
    text-align: center;
    color: #fff;
    padding: 1px 12px;
    margin-left: 8px;
    font-size: 12px;
}

.overlay-content .no-badge {
    background: #d34532;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 22px;
    text-align: center;
    color: #fff;
    padding: 1px 12px;
    margin-left: 8px;
    font-size: 12px;
}

.custom-camp-table .yes-badge {
    background: #41BEA7;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 22px;
    text-align: center;
    color: #fff;
    padding: 1px 12px;
    margin-left: 8px;
    font-size: 12px;
}

.custom-camp-table .no-badge {
    background: #d34532;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 22px;
    text-align: center;
    color: #fff;
    padding: 1px 12px;
    margin-left: 8px;
    font-size: 12px;
}

select.form-control option:disabled:hover {
    background-color: red;
}

.template-multiselect {
    width: 32% !important;
}
/* 
.select-panel>div{
    padding:5px !important;
} */

.template-multiselect .gray{
    margin: 5px 6px;
} 

.card-action {
    margin-left: -15px;
    margin-right: 10px;
}

.action-dropdown .show {
    border-radius: 5px !important;
}

.withTrain {
    margin-top: 10px !important;
   
}

.rightFilters .grid_table{
    border:unset;
    padding:unset;

}

.progress-D {
    width: 55px;
    height: 40px;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
}

.progress-D:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid #efefef;
    position: absolute;
    top: 0;
    left: 0;
}

.progress-D>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}

.progress-D .progress-D-left {
    left: 0;
}

.progress-D .progress-D-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 5px;
    border-style: solid;
    position: absolute;
    top: 0;
}

.progress-D .progress-D-bar.warining {
    border-color: #dd7404;

}

.progress-D .progress-D-bar.danger {
    border-color: #F74444;
}

.progress-D .progress-D-bar.success {
    border-color: #268d84;
}

.progress-D .progress-D-left .progress-D-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
}

.progress-D .progress-D-right {
    right: 0;
}

.progress-D .progress-D-right .progress-D-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
}

.progress-D .progress-D-value {
    display: flex;
    border-radius: 50%;
    font-size: 10px;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 300;
}

.progress-D .progress-D-value span {
    font-size: 10px;
    text-transform: uppercase;
}

/* This for loop creates the necessary css animation names 
  Due to the split circle of progress-D-left and progress-D right, we must use the animations on each side. 
  */
.progress-D[data-percentage="1"] .progress-D-right .progress-D-bar {
    animation: loading-1 0.5s linear forwards;
}

.progress-D[data-percentage="1"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="2"] .progress-D-right .progress-D-bar {
    animation: loading-2 0.5s linear forwards;
}

.progress-D[data-percentage="2"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="3"] .progress-D-right .progress-D-bar {
    animation: loading-3 0.5s linear forwards;
}

.progress-D[data-percentage="3"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="4"] .progress-D-right .progress-D-bar {
    animation: loading-4 0.5s linear forwards;
}

.progress-D[data-percentage="4"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="5"] .progress-D-right .progress-D-bar {
    animation: loading-5 0.5s linear forwards;
}

.progress-D[data-percentage="5"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="6"] .progress-D-right .progress-D-bar {
    animation: loading-6 0.5s linear forwards;
}

.progress-D[data-percentage="6"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="7"] .progress-D-right .progress-D-bar {
    animation: loading-7 0.5s linear forwards;
}

.progress-D[data-percentage="7"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="8"] .progress-D-right .progress-D-bar {
    animation: loading-8 0.5s linear forwards;
}

.progress-D[data-percentage="8"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="9"] .progress-D-right .progress-D-bar {
    animation: loading-9 0.5s linear forwards;
}

.progress-D[data-percentage="9"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="10"] .progress-D-right .progress-D-bar {
    animation: loading-10 0.5s linear forwards;
}

.progress-D[data-percentage="10"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="11"] .progress-D-right .progress-D-bar {
    animation: loading-11 0.5s linear forwards;
}

.progress-D[data-percentage="11"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="12"] .progress-D-right .progress-D-bar {
    animation: loading-12 0.5s linear forwards;
}

.progress-D[data-percentage="12"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="13"] .progress-D-right .progress-D-bar {
    animation: loading-13 0.5s linear forwards;
}

.progress-D[data-percentage="13"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="14"] .progress-D-right .progress-D-bar {
    animation: loading-14 0.5s linear forwards;
}

.progress-D[data-percentage="14"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="15"] .progress-D-right .progress-D-bar {
    animation: loading-15 0.5s linear forwards;
}

.progress-D[data-percentage="15"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="16"] .progress-D-right .progress-D-bar {
    animation: loading-16 0.5s linear forwards;
}

.progress-D[data-percentage="16"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="17"] .progress-D-right .progress-D-bar {
    animation: loading-17 0.5s linear forwards;
}

.progress-D[data-percentage="17"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="18"] .progress-D-right .progress-D-bar {
    animation: loading-18 0.5s linear forwards;
}

.progress-D[data-percentage="18"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="19"] .progress-D-right .progress-D-bar {
    animation: loading-19 0.5s linear forwards;
}

.progress-D[data-percentage="19"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="20"] .progress-D-right .progress-D-bar {
    animation: loading-20 0.5s linear forwards;
}

.progress-D[data-percentage="20"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="21"] .progress-D-right .progress-D-bar {
    animation: loading-21 0.5s linear forwards;
}

.progress-D[data-percentage="21"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="22"] .progress-D-right .progress-D-bar {
    animation: loading-22 0.5s linear forwards;
}

.progress-D[data-percentage="22"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="23"] .progress-D-right .progress-D-bar {
    animation: loading-23 0.5s linear forwards;
}

.progress-D[data-percentage="23"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="24"] .progress-D-right .progress-D-bar {
    animation: loading-24 0.5s linear forwards;
}

.progress-D[data-percentage="24"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="25"] .progress-D-right .progress-D-bar {
    animation: loading-25 0.5s linear forwards;
}

.progress-D[data-percentage="25"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="26"] .progress-D-right .progress-D-bar {
    animation: loading-26 0.5s linear forwards;
}

.progress-D[data-percentage="26"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="27"] .progress-D-right .progress-D-bar {
    animation: loading-27 0.5s linear forwards;
}

.progress-D[data-percentage="27"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="28"] .progress-D-right .progress-D-bar {
    animation: loading-28 0.5s linear forwards;
}

.progress-D[data-percentage="28"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="29"] .progress-D-right .progress-D-bar {
    animation: loading-29 0.5s linear forwards;
}

.progress-D[data-percentage="29"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="30"] .progress-D-right .progress-D-bar {
    animation: loading-30 0.5s linear forwards;
}

.progress-D[data-percentage="30"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="31"] .progress-D-right .progress-D-bar {
    animation: loading-31 0.5s linear forwards;
}

.progress-D[data-percentage="31"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="32"] .progress-D-right .progress-D-bar {
    animation: loading-32 0.5s linear forwards;
}

.progress-D[data-percentage="32"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="33"] .progress-D-right .progress-D-bar {
    animation: loading-33 0.5s linear forwards;
}

.progress-D[data-percentage="33"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="34"] .progress-D-right .progress-D-bar {
    animation: loading-34 0.5s linear forwards;
}

.progress-D[data-percentage="34"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="35"] .progress-D-right .progress-D-bar {
    animation: loading-35 0.5s linear forwards;
}

.progress-D[data-percentage="35"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="36"] .progress-D-right .progress-D-bar {
    animation: loading-36 0.5s linear forwards;
}

.progress-D[data-percentage="36"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="37"] .progress-D-right .progress-D-bar {
    animation: loading-37 0.5s linear forwards;
}

.progress-D[data-percentage="37"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="38"] .progress-D-right .progress-D-bar {
    animation: loading-38 0.5s linear forwards;
}

.progress-D[data-percentage="38"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="39"] .progress-D-right .progress-D-bar {
    animation: loading-39 0.5s linear forwards;
}

.progress-D[data-percentage="39"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="40"] .progress-D-right .progress-D-bar {
    animation: loading-40 0.5s linear forwards;
}

.progress-D[data-percentage="40"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="41"] .progress-D-right .progress-D-bar {
    animation: loading-41 0.5s linear forwards;
}

.progress-D[data-percentage="41"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="42"] .progress-D-right .progress-D-bar {
    animation: loading-42 0.5s linear forwards;
}

.progress-D[data-percentage="42"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="43"] .progress-D-right .progress-D-bar {
    animation: loading-43 0.5s linear forwards;
}

.progress-D[data-percentage="43"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="44"] .progress-D-right .progress-D-bar {
    animation: loading-44 0.5s linear forwards;
}

.progress-D[data-percentage="44"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="45"] .progress-D-right .progress-D-bar {
    animation: loading-45 0.5s linear forwards;
}

.progress-D[data-percentage="45"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="46"] .progress-D-right .progress-D-bar {
    animation: loading-46 0.5s linear forwards;
}

.progress-D[data-percentage="46"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="47"] .progress-D-right .progress-D-bar {
    animation: loading-47 0.5s linear forwards;
}

.progress-D[data-percentage="47"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="48"] .progress-D-right .progress-D-bar {
    animation: loading-48 0.5s linear forwards;
}

.progress-D[data-percentage="48"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="49"] .progress-D-right .progress-D-bar {
    animation: loading-49 0.5s linear forwards;
}

.progress-D[data-percentage="49"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="50"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="50"] .progress-D-left .progress-D-bar {
    animation: 0;
}

.progress-D[data-percentage="51"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="51"] .progress-D-left .progress-D-bar {
    animation: loading-1 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="52"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="52"] .progress-D-left .progress-D-bar {
    animation: loading-2 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="53"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="53"] .progress-D-left .progress-D-bar {
    animation: loading-3 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="54"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="54"] .progress-D-left .progress-D-bar {
    animation: loading-4 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="55"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="55"] .progress-D-left .progress-D-bar {
    animation: loading-5 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="56"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="56"] .progress-D-left .progress-D-bar {
    animation: loading-6 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="57"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="57"] .progress-D-left .progress-D-bar {
    animation: loading-7 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="58"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="58"] .progress-D-left .progress-D-bar {
    animation: loading-8 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="59"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="59"] .progress-D-left .progress-D-bar {
    animation: loading-9 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="60"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="60"] .progress-D-left .progress-D-bar {
    animation: loading-10 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="61"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="61"] .progress-D-left .progress-D-bar {
    animation: loading-11 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="62"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="62"] .progress-D-left .progress-D-bar {
    animation: loading-12 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="63"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="63"] .progress-D-left .progress-D-bar {
    animation: loading-13 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="64"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="64"] .progress-D-left .progress-D-bar {
    animation: loading-14 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="65"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="65"] .progress-D-left .progress-D-bar {
    animation: loading-15 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="66"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="66"] .progress-D-left .progress-D-bar {
    animation: loading-16 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="67"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="67"] .progress-D-left .progress-D-bar {
    animation: loading-17 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="68"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="68"] .progress-D-left .progress-D-bar {
    animation: loading-18 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="69"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="69"] .progress-D-left .progress-D-bar {
    animation: loading-19 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="70"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="70"] .progress-D-left .progress-D-bar {
    animation: loading-20 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="71"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="71"] .progress-D-left .progress-D-bar {
    animation: loading-21 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="72"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="72"] .progress-D-left .progress-D-bar {
    animation: loading-22 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="73"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="73"] .progress-D-left .progress-D-bar {
    animation: loading-23 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="74"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="74"] .progress-D-left .progress-D-bar {
    animation: loading-24 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="75"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="75"] .progress-D-left .progress-D-bar {
    animation: loading-25 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="76"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="76"] .progress-D-left .progress-D-bar {
    animation: loading-26 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="77"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="77"] .progress-D-left .progress-D-bar {
    animation: loading-27 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="78"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="78"] .progress-D-left .progress-D-bar {
    animation: loading-28 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="79"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="79"] .progress-D-left .progress-D-bar {
    animation: loading-29 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="80"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="80"] .progress-D-left .progress-D-bar {
    animation: loading-30 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="81"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="81"] .progress-D-left .progress-D-bar {
    animation: loading-31 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="82"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="82"] .progress-D-left .progress-D-bar {
    animation: loading-32 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="83"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="83"] .progress-D-left .progress-D-bar {
    animation: loading-33 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="84"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="84"] .progress-D-left .progress-D-bar {
    animation: loading-34 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="85"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="85"] .progress-D-left .progress-D-bar {
    animation: loading-35 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="86"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="86"] .progress-D-left .progress-D-bar {
    animation: loading-36 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="87"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="87"] .progress-D-left .progress-D-bar {
    animation: loading-37 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="88"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="88"] .progress-D-left .progress-D-bar {
    animation: loading-38 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="89"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="89"] .progress-D-left .progress-D-bar {
    animation: loading-39 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="90"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="90"] .progress-D-left .progress-D-bar {
    animation: loading-40 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="91"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="91"] .progress-D-left .progress-D-bar {
    animation: loading-41 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="92"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="92"] .progress-D-left .progress-D-bar {
    animation: loading-42 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="93"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="93"] .progress-D-left .progress-D-bar {
    animation: loading-43 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="94"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="94"] .progress-D-left .progress-D-bar {
    animation: loading-44 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="95"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="95"] .progress-D-left .progress-D-bar {
    animation: loading-45 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="96"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="96"] .progress-D-left .progress-D-bar {
    animation: loading-46 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="97"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="97"] .progress-D-left .progress-D-bar {
    animation: loading-47 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="98"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="98"] .progress-D-left .progress-D-bar {
    animation: loading-48 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="99"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="99"] .progress-D-left .progress-D-bar {
    animation: loading-49 0.5s linear forwards 0.5s;
}

.progress-D[data-percentage="100"] .progress-D-right .progress-D-bar {
    animation: loading-50 0.5s linear forwards;
}

.progress-D[data-percentage="100"] .progress-D-left .progress-D-bar {
    animation: loading-50 0.5s linear forwards 0.5s;
}

@keyframes loading-1 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(3.6);
        transform: rotate(3.6deg);
    }
}

@keyframes loading-2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(7.2);
        transform: rotate(7.2deg);
    }
}

@keyframes loading-3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(10.8);
        transform: rotate(10.8deg);
    }
}

@keyframes loading-4 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(14.4);
        transform: rotate(14.4deg);
    }
}

@keyframes loading-5 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(18);
        transform: rotate(18deg);
    }
}

@keyframes loading-6 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(21.6);
        transform: rotate(21.6deg);
    }
}

@keyframes loading-7 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(25.2);
        transform: rotate(25.2deg);
    }
}

@keyframes loading-8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(28.8);
        transform: rotate(28.8deg);
    }
}

@keyframes loading-9 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(32.4);
        transform: rotate(32.4deg);
    }
}

@keyframes loading-10 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(36);
        transform: rotate(36deg);
    }
}

@keyframes loading-11 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(39.6);
        transform: rotate(39.6deg);
    }
}

@keyframes loading-12 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(43.2);
        transform: rotate(43.2deg);
    }
}

@keyframes loading-13 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(46.8);
        transform: rotate(46.8deg);
    }
}

@keyframes loading-14 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(50.4);
        transform: rotate(50.4deg);
    }
}

@keyframes loading-15 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(54);
        transform: rotate(54deg);
    }
}

@keyframes loading-16 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(57.6);
        transform: rotate(57.6deg);
    }
}

@keyframes loading-17 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(61.2);
        transform: rotate(61.2deg);
    }
}

@keyframes loading-18 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(64.8);
        transform: rotate(64.8deg);
    }
}

@keyframes loading-19 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(68.4);
        transform: rotate(68.4deg);
    }
}

@keyframes loading-20 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(72);
        transform: rotate(72deg);
    }
}

@keyframes loading-21 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(75.6);
        transform: rotate(75.6deg);
    }
}

@keyframes loading-22 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(79.2);
        transform: rotate(79.2deg);
    }
}

@keyframes loading-23 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(82.8);
        transform: rotate(82.8deg);
    }
}

@keyframes loading-24 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(86.4);
        transform: rotate(86.4deg);
    }
}

@keyframes loading-25 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(90);
        transform: rotate(90deg);
    }
}

@keyframes loading-26 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(93.6);
        transform: rotate(93.6deg);
    }
}

@keyframes loading-27 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(97.2);
        transform: rotate(97.2deg);
    }
}

@keyframes loading-28 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(100.8);
        transform: rotate(100.8deg);
    }
}

@keyframes loading-29 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(104.4);
        transform: rotate(104.4deg);
    }
}

@keyframes loading-30 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(108);
        transform: rotate(108deg);
    }
}

@keyframes loading-31 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(111.6);
        transform: rotate(111.6deg);
    }
}

@keyframes loading-32 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(115.2);
        transform: rotate(115.2deg);
    }
}

@keyframes loading-33 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(118.8);
        transform: rotate(118.8deg);
    }
}

@keyframes loading-34 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(122.4);
        transform: rotate(122.4deg);
    }
}

@keyframes loading-35 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(126);
        transform: rotate(126deg);
    }
}

@keyframes loading-36 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(129.6);
        transform: rotate(129.6deg);
    }
}

@keyframes loading-37 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(133.2);
        transform: rotate(133.2deg);
    }
}

@keyframes loading-38 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(136.8);
        transform: rotate(136.8deg);
    }
}

@keyframes loading-39 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(140.4);
        transform: rotate(140.4deg);
    }
}

@keyframes loading-40 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(144);
        transform: rotate(144deg);
    }
}

@keyframes loading-41 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(147.6);
        transform: rotate(147.6deg);
    }
}

@keyframes loading-42 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(151.2);
        transform: rotate(151.2deg);
    }
}

@keyframes loading-43 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(154.8);
        transform: rotate(154.8deg);
    }
}

@keyframes loading-44 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(158.4);
        transform: rotate(158.4deg);
    }
}

@keyframes loading-45 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(162);
        transform: rotate(162deg);
    }
}

@keyframes loading-46 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(165.6);
        transform: rotate(165.6deg);
    }
}

@keyframes loading-47 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(169.2);
        transform: rotate(169.2deg);
    }
}

@keyframes loading-48 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(172.8);
        transform: rotate(172.8deg);
    }
}

@keyframes loading-49 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(176.4);
        transform: rotate(176.4deg);
    }
}

@keyframes loading-50 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(180);
        transform: rotate(180deg);
    }
}

.evs-content .evs-icon {
    color: #343A40;
    background-color: #CCCECF;
    padding: 5px;
    border-radius: 5px;
    margin-right: 9px;
}

.evs-content h5 {
    text-transform: capitalize;
    font-size: 23px;
    color: #2a3042;
}