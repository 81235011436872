.btn-dots {

    background-color: transparent !important;
    color: #2a3042 !important;
    border: none !important;

}

.create-wrap {
    padding: 0px 10px;
}

 .borderRemove .dropdown-container{
 border: unset !important;
 height:unset !important;
 padding:unset !important;
 color: #7a7a7a !important;
 font-size: 13px !important;
}

.campRadiobtn .form-check-input{
    margin-top: 0px !important;
}


.crtAttackType{
    display: flex;
    margin-bottom: 10px;
    margin-top: 5px;
}
.crtAttackType h6{
    background: #f0f0f0;
    color: #121212;
    padding: 10px 15px;
    font-size: 14px;
    margin-right: 15px;
    border-radius: 10px;
    font-weight: 600;
    border: 1px solid #ced4da;
}
.crtAttackType span{
    font-weight: 600;
    color: #135cb2;
}




.campRadiobtn .form-check{
display: flex;
align-items: center;
padding-right: 40px;
}

.campRadiobtn{
    margin-left: 25px !important;
}

.campRadiobtn .form-check-input{
    width: 13px !important;
    height: 13px !important;
    position: unset !important;
    margin-right: 5px !important;
   
}

.attackTypename span{
font-weight: 800;
}

.create-wrap h5 {
    font-weight: 600;
    color: #494a4c;
    margin-bottom: 15px;
    background: #F7F7F7;
    padding: 15px;
}


.create-wrap .go2646822163 {
    height: 35px;
    border-bottom: 1px solid #ced4da;
    border-radius: 2px !important;
    font-size: 13px;
}

.create-wrap .go2139093995 .gray {
    color: #495057;
}

.freq-list .list-group-item.active {
    background-color: #2A3042;
    border-color: #2A3042;
    z-index: 0;
}

.freq-list .list-group-item {
    border: 1px solid rgb(0 0 0 / 72%);
    text-align: center;
    cursor: pointer;
}

.react-datetime-picker {
    width: 100%;
}

.react-datetime-picker__wrapper {
    border: none;
    border-bottom: 1px solid #ced4da;
    border-radius: 0px !important;
}

.campaign-check label {
    width: unset !important;
}
.campaign-check {
    text-align: right;
}
.rowscheck {
    display: flex;
    justify-content: center;
}
.rowscheck .form-check-label {
    font-size: 15px !important;
}

.verfiy_list{
    display: flex;
    justify-content: center;
    padding-left: 0 !important;
    margin-bottom: 0;
}
.verfiy_list li{
    list-style: none;
    position: relative;
    width: 45%;
    margin-right: 20px;
    padding: 12px 12px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.verfiy_list li h5{
    margin-top: 10px;
}
.icon_position{
    position: absolute;
    background: #28a745;
    width: 20px !important;
    height: 20px !important;
    border-radius: 100%;
    color: #fff;
    right: -12px;
    top: -12px;
}
.active_cl {
    border: 2px solid #28a745;
    background-color: #f8f9fa;
    position: relative;
}
.verfiy_list li img {
    width: 40px;
}
.modal-cancel{
    position: absolute;
    right: 0px;
    top: 0px;
    background: #fff;
    border-radius: 32px;
    z-index: 1;
    cursor: pointer;
}
.overview-campaign {
    align-items: center;
    display: flex;
    margin-left: 22px;
} 

.overview-campaign .form-check{
  margin-right: 50px;
}

.cmpaigntemplateBox .noteSection{
    display: inline-block;
    margin: 0;
}
.cmpaigntemplateBox .noteSection p{
    padding: 8px 15px;
}

.overview-campaign label{
    font-size: 13px;
}
.custom-switch121{
    margin-bottom: 25px;
}
.custom-switch121 label{
    font-size: 13px;
    margin-bottom: 15px;
}
.multi-select {
    border: 1px solid #ccc !important;
    border-radius: 6px !important;
}
.multi-select.empty{
    border-color: #dc3545 !important;
}

.multi-select.empty .dropdown-container{
    border:none;
    height: 0 !important;
    padding: 0 !important;
}




.campLaunchmodal .modal-dialog{
    max-width: 675px !important;
}

.campLaunchmodal .simselectCard{
    border-bottom:unset !important;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.simselectCard{
    padding-bottom: 22px;
    border-bottom: 2px dotted #e2e5ed;
    margin-bottom: 20px;
  }
  
  .simselectCard .simmainFlex{
    display: flex;
  align-items: center;
  }
  
  .campLaunchmodal .simselectCard .simCard{
    list-style: none;
    position: relative;
    width: 50%;
    margin: 0px 10px;
    padding: 30px 0px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    background: #e9ecef69;
  
  }
  
  .simIcon{
    margin-bottom: 15px;
  }
  
  .simIcon img{
    height: 50px;
  }

  .simselectCard .simCard h6{
    font-size: 16px;
    margin-bottom: 2px;
  }
  
  .simselectCard .simCard p{
    color: #686868;
    font-size: 13px;
    margin-bottom: 0px;
    font-weight: 300;
  }
  
