#preview .preview-head {
  align-items: center;
  padding: 8px 0px;
  margin-bottom: 20px;
}

#preview .preview-head h5 {
  margin-bottom: 0px;
  font-size: 20px;
}

#preview h6 {
  color: #2f3031;
  font-weight: 700;
}

#preview .l-head p {
  color: #2f3031;
  font-weight: 600;
  line-height: 30px;
}

#preview .r-para .p-badge {
  background: #d5eefd;
  width: 90%;
  text-align: center;
  border-radius: 15px;
  font-size: 14px;
  padding: 3px 0px;
}

#preview .r-para p {
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#preview .yes-badge {
  background: #41bea7;
  width: fit-content;
  border-radius: 22px;
  text-align: center;
  color: #fff;
  padding: 3px 15px;
}

#preview .no-badge {
  background: #f85c5c;
  width: fit-content;
  border-radius: 18px;
  color: #fff;
  padding: 3px 15px;
  text-align: center;
}

#preview .p-img-head {
  width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0px;
}

#preview hr {
  margin: 2rem 0px;
}

#preview .hover-btn {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

#preview .img-hover-wrap:hover {
  background: #f8f9fa;
}

.templateView {
  cursor: pointer;
}
.templateView .btn-icon{
  font-size: 50px !important;
}

/* #preview .img-hover-wrap {
  padding: 20px;
  border: 1px solid #d6d6d6;
  min-height: 250px;
} */

.img-hover-wrap:hover img {
  opacity: 0.3 !important;
}

.img-hover-wrap:hover .hover-btn {
  opacity: 1 !important;
}
.training-table-preview .table-responsive {
  overflow-x: auto !important;
}
#preview .training-img {
  width: 50px;
  height: 50px;
  margin: 0px;
}

/*************************
Preview modal css
***********************/

.right-preview-modal .modal-dialog {
  width: 68% !important;
  right: 244px !important;
  max-width: 68% !important;
}

.right-preview-modal .modal-body {
  padding: 0px !important;
}

.right-preview-modal .breadcrumb-wrapper {
  height: unset !important;
  border-bottom: 1px solid #e2e5ed;
  padding: 6px 15px;
}

.right-preview-modal .textMuted {
  font-size: 11px;
  color: #828282;
  margin-bottom: 0;
}

.filter-padding {
  padding: 6px 10px !important;
}

.right-preview-modal .table thead th, .right-preview-modal .table tbody td {
  border: unset !important;
  padding: 0px 0px 15px !important;
  font-weight: 700;
}

.valueHeading {
  font-size: 13px !important;
  color: #949494;
}

.keyHeading {
  font-size: 13px !important;
  color: #212529;
  width: 40%;
}

.templateDetailsWrapper {
  padding: 20px 15px 0px 15px;
}

.badgeYn {
  padding: 2px 10px !important;
  border-radius: 3px !important;
  font-size: 11px !important;
  display: inline-block !important;
}

.no-badges {
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
}

.yes-badges {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}

.tabTamplate .tab-content {
  border: unset !important;
}

.tabTamplate .contHeading {
  margin: 8px 0px;
}

.contHeading h6 {
  font-size: 13px;
  margin-bottom: 0;
}

.checkBox {
  display: flex;
  align-content: center;
}

.tabTamplate .cardImg {
 
  border: 1px solid #e2e5ed;
  border-radius: 5px;
  padding: 10px;
  height: 200px;
}

.tabTamplate img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.url-wrapper {
  margin-top: 20px;
}

.url-wrapper .keyHeading {
  width: 65%;
  font-weight: 700;
}

.url-wrapper .contHeading h6 {
  padding-bottom: 4px;
}

.url-wrapper .contHeading {
  margin-bottom: 20px;
}

.url-wrapper .contHeading p {
  font-size: 12px !important;
  color: #949494;
  margin-bottom: 0px;
}
