.emailCard img{
    max-width: 100%;
    min-height: 200px;
}

.emailCard .midCard{
padding: 10px;
border: 2px solid #28a745;
background-color: #f8f9fa;
position: relative;
overflow: unset;
box-shadow: unset;
}

.iconPosition{
    position: absolute;
    background: #28a745;
    width: 18px !important;
    height: 18px !important;
    border-radius: 100%;
    color: #fff;
    right: -8px;
    top: -10px;
}
.modal.emailRight .modal-body{
    padding: 20px 40px 10px 20px !important;
}

.footerButton{
    position: absolute;
    bottom: 13px;
    right: 0;
    width: 100%;
    padding-right: 125px !important;
    border-top: unset !important;
}