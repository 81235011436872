
.sticky-summary{
    position: relative;
    /* height: 1.6rem; */
    width:fit-content;
    padding: 4px 10px;
    background: antiquewhite;
    border-radius: 4px;
    margin: 0 6px 6px 0;
}

.errorInfo {
    font-size: x-small;
    color: rgb(224, 154, 62);
    padding: 4px;
}

/* .live-form div {
    padding: 0px;
} */