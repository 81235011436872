/* .training-table.table thead th,
.table tbody td {
  border-bottom: 1px solid #dee2e6 !important;
} */
.training-icon {
  color: #1867d2 !important;
  font-size: 15px !important;
  margin-right: -5px;
  cursor: pointer;
  padding-left: 2px !important;
}
.training-preview-icon {
  cursor: pointer;
  color: blue;
}
.btnSuccessLight {
  background: #d1e7dd !important;
  color: #0a3622 !important;
  text-transform: uppercase !important;
  font-size: 11.5px !important;
  font-weight: 600 !important;
  border: none !important;
}

.trainingSearch{
  border-bottom:unset !important;
}

.trainingSearch .input-group{
  border: 1px solid #e2e5ed !important;
}

.trainingSearch select{
  border: 1px solid #e2e5ed !important;
}


.traningNumber{
padding: 0px 0px 10px !important;
}

.traningNumber .form-group{
  margin-bottom: 0px;
}

.trainingSearch .form-control{
  min-height: 32px !important;
  height: 32px !important;
}


.trainingSearch .input-group{
  border-radius: 5px;
}
