.count-block{
    background: #f2f3f5;
    padding: 24px;
    text-align: center;
    min-height: 138px;
}
.count-block h4{
    font-size: 31px;
    margin-bottom: 0px;
}
.count-block h6{
    height: 40px;
}
