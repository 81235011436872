.forget-msg{
    background: rgb(62 214 68 / 11%);
    padding: 20px;
    border-radius: 10px;
}
.forget-msg h5{
    font-weight: 600;
    font-size: 18px;
    color: #5d5e61;
}
.forget-msg h6{
    color: #36373a;
    font-size: 16px;
}
.forget-msg p{
    color: #6e7075;
    font-size: 14px;
    margin-bottom: 0px;
    display: flex;
    line-height: 32px;
}
.forget-msg .btn-link {
    background: transparent;
    border: none;
    padding: 0px;
    color: #5473e8;
    font-size: 14px !important;
}
.forget-msg .btn-link:hover, .forget-msg .btn-link:focus{
    background-color: transparent;
    border-color: none;
    color: #20387b !important;
    text-decoration: none;
}