
.mainBodyChildren{
    margin-top: 60px;
}


.dashboard_Body{
    background: rgb(246, 246, 246);
    padding: 10px 15px;
}

.dashboard_Body .card-wrap{
    background: #fff;
    border: 1px solid #e2e5ed;
    border-radius: 5px;
    padding: 10px;
    position: relative;
}

.dashboard_Body .pd_dash{
    padding: 5px;
}


#dashboard .left-dash .card-block{
    background: #fff;
    box-shadow: 0 5px 12px 0 rgba(0,0,0,.11), 0 0 1px 0 rgba(0,0,0,.1);
    margin: 0px 10px;
    flex: 1 0 13.44%;
    max-width: 19.50%;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

#dashboard .left-dash .card-block .icon-one{
    background: #5570e62e;
    color: #5570E6;
}

#dashboard .left-dash .card-block .dash-icon{
    padding: 5px;
    border-radius: 15px;
    margin-right: 8px;
}
#dashboard .left-dash .card-block .icon-two{
    background: rgb(255 0 0 / 18%);
    color: red;
}
#dashboard .left-dash .card-block .icon-three{
    background: rgb(8 160 247 / 20%);
    color: #08A0F7;
}

#dashboard .left-dash .card-block .icon-four{

    background: rgb(250 104 56 / 24%);
    color: #FA6838;
}

#dashboard .left-dash .card-block.gray{
    background: #F9FAFC;
}

#dashboard .left-dash .card-block h6{
    color: #8e9094;
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 15px;
}
#dashboard .left-dash .card-block h4{
    font-size: 25px;
    margin: 15px 0px;
    font-weight: 600;
}
#dashboard .left-dash .card-block p{
    margin-bottom: 0px;
    font-size: 12px;
    color: #8d8e90;
}
#dashboard .left-dash .card-block .arrow-icon{
    font-size: 13px;
    margin-top: -2px;
}

#dashboard .card-wrap h6{
    color: #2a3042;
    font-weight: 600;
    margin-bottom: 10px;
}
#dashboard .left-dash .card-wrap .rating-num h3{
    font-size: 45px;
    margin-bottom: 0px;
    font-weight: 500;

}
#dashboard .left-dash .card-wrap .rating-num .rate-text{
    margin-bottom: 0px;
    font-size: 12px;
    margin-left: 10px;
}

#dashboard .left-dash .card-wrap .rating-num .para{
    margin-bottom: 0px;
    font-size: 11px;
    color: #8e9297;
}
#dashboard .left-dash .card-wrap .rating-num .green-icon{
    color: #ffffff;
    background: #4fc68c;
    border-radius: 5px;
}   

#dashboard .left-dash .card-wrap .rating-num .border-bottom{
    padding-bottom: 12px;
}
#dashboard .left-dash .card-wrap .rating-num .line-graph{
    width: 112px;
}
#dashboard .left-dash .card-wrap .rating-num .green-arrow{
    color: #ffffff;
    background: #4fc68c;
    border-radius: 15px;
    font-size: 14px;
}

#dashboard .left-dash .card-wrap .rating-num .green-text{
    color: #4fc68c;
    font-size: 11px;
}
#dashboard .left-dash .card-wrap .bottom-wrap{
    border: 1px solid #d2d2d2;
    border-radius: 10px;
    margin-top: 10px !important;
}
#dashboard .left-dash .card-wrap .bottom-wrap p{
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 11px;
    color: #8e9094;
    font-weight: 600;
    margin-bottom: 0px;
}

#dashboard .left-dash .card-wrap .bottom-wrap h5{
    font-size: 29px;
    margin: 5px 0px;
}

#dashboard .left-dash .card-wrap .dot{
    display: block;
    height: 12px;
    width: 12px;
    background: #6E67D3;
    border-radius: 3px;
    margin-right: 7px;
}

.blur-attacks{
    color: #d3d2e0 !important;
}

#dashboard .left-dash .card-wrap .dot.greend{
    background: #4FC68C;
}
#dashboard .left-dash .card-wrap .dot.blue{
    background: #418AFA;
}
#dashboard .left-dash .card-wrap .border-l-r{
    border-left: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
}
#dashboard .left-dash .card-wrap .progress{
    height: 3px;
    margin-bottom: 15px;
    margin-top: 10px;
}
#dashboard .left-dash .card-wrap .bg-voilet.progress .progress-bar{
    background: #6E67D3;
}
#dashboard .left-dash .card-wrap .bg-green.progress .progress-bar{
    background:#4fc68c ;
}
#dashboard .left-dash .card-wrap .bg-blue.progress .progress-bar{
    background: #418AFA;
}
#dashboard .left-dash .card-wrap .camp-links{
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    padding-bottom: 10px;
}
#dashboard .left-dash .card-wrap .camp-links:last-child{
    border-bottom: none;
}
#dashboard .left-dash .card-wrap .camp-links .r-arrow{
    position: absolute;
    right: 0px;
    font-size: 14px;
}
#dashboard .left-dash .card-wrap .camp-links h6{
    margin-bottom: 0px;
    font-size: 14px;
    width: 133px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
}
#dashboard .left-dash .card-wrap .camp-links p{
    margin-bottom: 0px;
    font-size: 13px;
    color: #8a8b8e;
}
#dashboard .left-dash .card-wrap .camp-links img{
    height: 40px;
}
#dashboard .left-dash .card-wrap .geo-rating p{
    margin-bottom: 0px;
    font-size: 13px;
    color: gray;

}
#dashboard .left-dash .card-wrap .geo-rating h5{
    color: #2a3042;
    font-size: 22px;
}
#dashboard .left-dash .card-wrap .geo-rating .l-r-mid{
    margin: 0px 13px;
    padding: 0px 15px;
}
#dashboard .left-dash .card-wrap  .mid-text .location{
    margin: 0px 0px 5px 0px;
}
#dashboard .left-dash .card-wrap table.top-countries td{
    padding: 6px !important;
}
#dashboard .last-block{
    height: 435px;
    margin-bottom: 102px !important;
}
#dashboard .right-dash .card-wrap.first{
    padding: 0px;
}
#dashboard .right-dash .card-wrap.first img{
    margin-top: 0px;
}
#dashboard .right-dash .card-wrap.first .welcome-text{
    padding: 20px;
}
#dashboard .right-dash .card-wrap.first .welcome-text p{

    color: #93929a;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;


}
#dashboard .right-dash .card-wrap.first .welcome-text h4{
    font-size: 18px;
    color: #0c1e47;
}

#dashboard .right-dash .card-wrap.first .welcome-text .dot{
    padding: 6px;
}
#dashboard .right-dash .card-wrap.first .welcome-text .dot.blue{
    color: #DC143C;
}
#dashboard .right-dash .card-wrap.first .welcome-text .dot.green{
    color: #F1B44C;
}
#dashboard .right-dash .card-wrap.first .welcome-text .dot.red{
color: #34C38F;
}
#dashboard .right-dash .card-wrap.first .welcome-text .dot.yellow{
color: #556EE6;
}
#dashboard .right-dash .card-wrap.first .welcome-text .dot.purplee{
    color: #A020F0;
}

#dashboard .right-dash .card-wrap .package-text{
    align-items: baseline;
}
#dashboard .right-dash .card-wrap .package-text h4{
    margin-bottom: 0px;
    font-weight: 600;
}
#dashboard .right-dash .card-wrap .package-text h6{
    margin-bottom: 0px;
    font-weight: 100;
    margin-left: 7px;
}
#dashboard .right-dash .card-wrap .bg-light.r-bg{
    border-radius: 10px;
    padding: 10px;
}
#dashboard .right-dash .card-wrap .bg-light.r-bg p{
    margin-bottom: 0px;
    font-size: 12px;
}
#dashboard .right-dash .card-wrap .bg-light.r-bg .progress{
    height: 7px;
    margin: 18px 0px 10px 0px;
}

#dashboard .right-dash .card-wrap .bg-light.r-bg .expiry-block p{
    font-size: 11px;
}
#dashboard .right-dash .card-wrap .bg-light.r-bg .expiry-block .pink-text{
    font-size: 11px;
    color: #de437f;
}

#dashboard .right-dash .card-wrap table.vaulnerable-table td{
    border-top: none;
    vertical-align: middle;
    padding: 14px 5px !important;
    text-transform: capitalize;
}

#dashboard .right-dash .card-wrap table.vaulnerable-table tr{
    border-bottom: 1px solid #f1f1f1;
}

#dashboard .table tbody td, .training-table.table thead th {
    border-bottom: none !important;
}

#dashboard .right-dash .card-wrap table.vaulnerable-table td:last-child{
    border-bottom: none !important;
}
#dashboard .right-dash .card-wrap table.vaulnerable-table{
    white-space: nowrap;
}

#dashboard .right-dash .card-wrap table.vaulnerable-table p{
    margin-bottom: 0px;
    font-size: 12px;
    color: #9da3b5;
}
#dashboard .right-dash .card-wrap table.vaulnerable-table td .rbga-name{
    background: rgb(42 48 66 / 11%);
    height: 35px;
    width: 35px;
    border-radius: 28px;
    display: flex;
    align-items: center;
    padding: 13px;
    color: #2a3042;
    font-weight: 600;
    text-transform: uppercase;
}
#dashboard .recent-camp {
    margin-bottom: 20px !important;
}
.height-614{
    height: 630px;
}
.height-375{
    height: 375px;
}
.map-wrap svg{
    height: 314px;
}

#dashboard .disabled-attack{
    
}
#diwali-modal {
    z-index: 100001;
}
#diwali-modal .radius{
    border-radius: 5px!important;
}
#diwali-modal .modal-body {
    position: relative;
    flex: 1 1 auto;
    border-radius: 5px;
    padding: 0px !important;
}