.modal.report-modal .modal-dialog{
    max-width: 1112px;
}
.rdrDateRangePickerWrapper{
    width: 100%;
}
.rdrCalendarWrapper.rdrDateRangeWrapper{
    width: 70%;
}
.report-modal .filter-wrap{
    background: #F7FAFC;
    padding: 15px;
}
.report-modal .modal-cancel{
    position: absolute;
    right: -20px;
    top: -22px;
    background: #fff;
    font-size: 29px !important;
    border-radius: 32px;
    height: 45px;
    width: 45px;
    padding: 13px;
    z-index: 1;
    cursor: pointer;
}
.btn-row{
    right: 16px;
    bottom: 26px;
}