#auth-section .left-wrap {
    height: 100vh;
    position: relative;
    background-image: url(../../assets//login-bg.png);
    background-size: cover;
}

#auth-section .left-wrap .login-img {
    width: 92%;
    margin-bottom: 20px;
}

#auth-section .left-wrap .text-wrap {
    text-align: center;
}

#auth-section .left-wrap .text-wrap h5 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}

#auth-section .left-wrap .text-wrap p {
    font-size: 14px;
    color: #b8c0dc;
}

#auth-section .right-wrap {
    /* background: #fff; */
    height: 100vh;

}

#auth-section .right-wrap .threatcop-logo {
    width: 150px;
    margin: 77px auto 32px !important;
}

#auth-section .right-wrap .content .login-box {
    width: 375px;
    background: #fff;
    border: 1px solid #f8f9fc;
    border-radius: 12px;
    box-shadow: 0 4px 16px rgb(0 0 0 / 15%);
    display: flex;
    flex-direction: column;
    padding: 40px;

}

#auth-section .right-wrap .content .login-box label {
    font-weight: 600;
    color: #6c757d !important;
    cursor: pointer;
    font-size: 14px !important;
}

#auth-section .right-wrap .content .login-box h4 {
    font-size: 22px;
    margin-bottom: 22px;
    text-align: center;
    font-weight: 600;
    color: #2b2b2b;
}

#auth-section .right-wrap .content .login-box h5 {
    font-size: 15px;
    line-height: 30px;
    color: #6a859a;
}

#auth-section .right-wrap .content .login-box .form-label {
    font-size: 14px !important;
    color: #6a859a !important;

}

#auth-section .right-wrap .content .login-box .form-control {
    padding: 0.375rem 0.75rem;
    background: #F6F6F6 !important;
    border: 1px solid #ced4da;
    border-radius: 0.25rem !important;
}

#auth-section .right-wrap .content .login-box .form-control::placeholder {
    color: #9a9c9e;
    font-size: 13px;
}

#auth-section .right-wrap .content .login-box .form-control:focus {
    box-shadow: none;
    background: #fff;
}

#auth-section .right-wrap .content .login-box .btn-link {
    background: transparent;
    border: none;
    padding: 10px !important;
    color: #5473e8;
    text-align: left;
    text-align: center;
}

#auth-section .right-wrap .content .login-box .link-color{
    color: #007bff !important;
    cursor: pointer;
}

#auth-section .right-wrap .content .login-box .btn-link:hover {
    text-decoration: none;
    color: #203c83;
}

#auth-section .right-wrap .content .login-box .btn-login {
    background: #0E2954;
    border-color: #0E2954;
    padding: 10px !important;
    font-size: 12px !important;
    margin: 6px 0px;
    width: 100% !important;
}

#auth-section .right-wrap .content .login-box p {
    font-size: 13px !important;
    margin-bottom: 0px !important;
    display: flex !important;
    justify-content: center !important;
}

.form-company {
    padding: 0px 10px !important;
}

.otp-timer {
    position: absolute;
    right: 10px;
    top: 9px;
    color: #297090;
    font-size: 13px;
}

.showPassword {
    position: absolute;
    right: 6%;
    cursor: pointer;
}

.showPasswordPeekViewAlignment {
    position: relative;
    display: flex !important;
    align-items: center;
    justify-content: center;
}