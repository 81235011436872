#bluePage-wrap{
    background-image: url('../../assets/blue-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

#bluePage-wrap .l-wrap{
    height: 100vh;
    display: flex;
    align-items: center;
}
#bluePage-wrap .left-wrap{
    padding: 30px 0px 30px 75px;
}
#bluePage-wrap .email-img{
    /* height: 500px; */
}
#bluePage-wrap .mid-img{
    display: flex;
    align-items: center;
    height: 100vh;
    text-align: center;
}
#bluePage-wrap .main-head{

    color: #ED4335;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 3px;

}
#bluePage-wrap .sub-head{
    color: #fff;
    font-size: 21px;
}

#bluePage-wrap .pointer-wrap{
    color: #fff;
    margin-top: 15px;
}
#bluePage-wrap .pointer-wrap .outer-circle{

    border: 2px solid #ed4335;
    height: 54px;
    width: 55px;
    padding: 7px;
    display: flex;
    border-radius: 40px;
    margin-right: 14px;

}
#bluePage-wrap .pointer-wrap .outer-circle .num{
    height: 35px;
    width: 35px;
    background: #ed4335;
    border-radius: 30px;
    text-align: center;
    line-height: 35px;
    font-size: 14px;
    font-weight: 600;
}
#bluePage-wrap .pointer-wrap .para h6{
    font-size: 20px;
    font-weight: 600;
    color: #F2F4F5
}
#bluePage-wrap .pointer-wrap .para .line{
    display: block;
    height: 2px;
    width: 45px;
    background: #ed4335;
    margin: 10px 0px;
}
#bluePage-wrap .pointer-wrap .para p{
    color: #9fa7ad;
    font-size: 14px;
    /* height: 45px; */
}
@media only screen and (min-width:300px) and (max-width:480px){
    #bluePage-wrap{
        height: 100%;
        overflow: auto;
    }
    #bluePage-wrap .mid-img{
        display: none;
    }
    #bluePage-wrap .l-wrap{
        height: 100%;
    }
    #bluePage-wrap .left-wrap{
        padding: 30px;
    }
    #bluePage-wrap .pointer-wrap .para p{
        height: auto;
    }
}