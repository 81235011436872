.form-box h6{
    font-weight: 700;
}
.dash-block{
    border: 1px dashed #c5c3c3;
    padding: 20px;
    display: block;
    text-decoration: none;
    color: #2a3042;
    text-align: center;
}
.dash-block:hover{
    text-decoration: none;
    color: #2a3042;

}
.dash-block img{
    height: 65px;
    margin-bottom: 10px;
}
.dash-block p{
    font-size: 12px;
    margin-bottom: 0px;
}
.right-img{
    width: 759px;
    float: right;
}
.total-users{
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}    

.total-users .user{
    margin-right: 15px;
    margin-bottom: 0;
}
.total-users .numbers{
    color: green;
}


.creatgroupSection .cmpaigntemplateBox{
    height: calc(100vh - 245px) !important;

}




.creatGroupModal .modal-dialog{
    right: 162px !important;
    width: 650px !important;
}

.creatGroupModal .modal-body{
    padding: 0px !important;
}

.creatGroupModal .uploadBlock{
text-align: center;
}

.creatGroupModal .uploadBlock{
    margin-top: 20px;
    padding: 20px;
    border: 1px dashed #c5bcbc;
    }

    .creatGroupModal .uploadBlock h6{
        font-size: 14px;
        font-weight: 600;
        margin-top: 15px;
        margin-bottom: 4px;
    
    }



    .creatGroupModal .uploadBlock svg{
        font-size: 50px;
        color: grey;
    }

    .creatGroupModal .uploadBlock p{
        color: #949494 !important;
        font-size: 11px;
        margin-bottom: 0px;
    }

    .creatGroupModal .uploadBlock a{
        font-size: 12px;
        text-decoration: underline;
        color: #007bffa1;
    }

    .creatGroupModal .uploadBlock .emailAttachment .form-file {
        width: 50%;
        border: 1px solid #ced4da;
        margin: 0 auto;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
    }

    

  