#delete-account .card {
    margin-top: 100px;
    right: 300px;
    left: 300px;
    position: absolute;
}

#delete-account .card .mini-img {
    width: 200px;
    margin-bottom: 20px;
}
.heading_delete{
    position: relative;
    padding-bottom: 10px;
}

.heading_delete:after{
    position: absolute;
    content:" ";
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 2px;
    background-color: #2a2f44;
}
#delete-account .main-image img{
    width: 250px !important;
    margin-bottom: 20px;
}

.delete-label {
    font-size: 13px;
    text-align: end;
    color: #8fa0af;
}
.delete-icon-color{
    color: green !important;
}