.choose-wrap .accordian-card {
  margin: 0px 0px 12px 0px !important;
  border: 1px solid #f2f2f2 !important;
}
.choose-wrap .accordian-card:last-child {
  margin-bottom: 5px !important;
}
.choose-wrap .accordian-card .card-body {
  padding: 15px 15px 5px 15px !important;
  background: #fafeff;
}
.choose-wrap .accordian-card .card-header {
  background-color: #fafeff !important;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 0px !important;
  cursor: pointer;
  padding: 10px;
}
.accord-head {
  font-size: 14px;
}
.chooseModal .modal-header {
  background-color: transparent !important;
  padding: 12px 15px;
}
.chooseModal .modal-footer {
  padding: 9px 15px;
}
.chooseModal .modal-dialog {
  max-width: 620px;
}
.choose-wrap .img-wrap img {
  width: 35px;
  margin-right: 15px;
}
.choose-wrap .img-wrap {
  border-right: 1px solid #f2f2f2;
  margin-right: 15px;
}
.choose-wrap .accordian-card .img-box {
  border: 1px solid #e2dddd;
  padding: 14px;
  text-align: center;
  border-radius: 6px;
}
.choose-wrap .accordian-card .img-box img {
  height: 33px;
}

label {
  width: 100%;
}

.card-input-element {
  display: none;
}

.card-input:hover {
  cursor: pointer;
}

.card-input-element:checked + .card-input {
  border: 3px solid #266fd6;

}


.activeBorder{
  box-shadow: 0 0 2px 2px #2ecc71;
  border-radius: 7px !important;
}

.inactiveBorder{
  background-color: unset !important;
}

.launch .card-input {
  margin: 0px !important;
  min-height: 0px !important;
}

.choose-wrap .col-md-2 {
  width: 20% !important;
  padding: 0px 7px;
  max-width: initial;
}
.chooseModal .form-check-label {
  font-size: 14px;
}
.disabledCls {
  opacity: 0.5 !important;
  border: 1px solid #bbbbbb !important;
  cursor: not-allowed !important;
  background: #9495821c !important;
}

.settings-Vsec {
  margin-top: 20px;
  margin-bottom: 25px;
}

.settings-Vsec h5{
  letter-spacing: 0.5px !important;
  font-size: 11px !important;
  text-transform: uppercase !important;
  margin-right: 16px !important;
  font-weight: 600 !important;
  margin-bottom: 0px !important;

}


.settings-Vsec .lcCardP {
  font-size: 11px !important;
  color: #696969 !important;
  margin-bottom: 0px;
  margin-top: 3px !important;
}

.settings-Vsec .custom-switch .custom-control-label::after{
  width: calc(11px - 1px) !important;
  height: calc(16px - 5px) !important;
  top: calc(4px + 2px) !important;
}

.settings-Vsec .custom-control-label::before{
  height: 15px !important;
}

.settings-Vsec .settingBox{
margin-bottom: 25px !important;
}


.page-separator {
  position: relative;
  color: #868e96;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 0;
}
.page-separator:before {
  content: "";
  height: 1px;
  width: 100%;
  top: 50%;
  position: absolute;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.1);
}
.page-separator__text {
  display: inline-flex;
  max-width: 90%;
  text-transform: uppercase;
  font-size: 12px;
  color: #303840;
  font-weight: 600;
  letter-spacing: 2px;
  border-radius: 0.25rem;
  background-color: #fff;
  padding-right: 0.5rem;
  line-height: 20px;
}

.toggleSwitchBtn {
  display: flex;
  justify-content: center;
}

.creatcampModal .modal-90w{
  max-width: 675px !important;
}

.simselectCard{
  padding-bottom: 22px;
  border-bottom: 2px dotted #e2e5ed;
  margin-bottom: 20px;
}

.simselectCard .simmainFlex{
  display: flex;
align-items: center;
}

.simselectCard .simCard{
  list-style: none;
  position: relative;
  width: 50%;
  margin: 0px 10px;
  padding: 12px 12px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  background: #e9ecef69;

}

.simIcon{
  margin-bottom: 15px;
}

.simIcon img{
  height: 70px;
}

.simselectCard .simCard h6{
  font-size: 16px;
  margin-bottom: 2px;
}

.simselectCard .simCard p{
  color: #686868;
  font-size: 13px;
  margin-bottom: 0px;
  font-weight: 300;
}

.campAttack h6{
font-size: 13px;
color: #9c9c9c;
}

.campattackCard .launch{
  padding-left: 10px !important;
  padding-right: 10px !important;
  flex: 0 0 20%;
  max-width: 20%;
 }

 .campattackCard .launch:nth-child(n+6){
  margin-top: 20px !important;
 }

 .campattackCard .row{
  margin: 0px -10px;
 }

.simselectCard h6, .campattackCard h6{
font-size: 14px;
padding-bottom: 5px;
}


.campattackCard .campName{
  display: flex;
  align-items: center;
  justify-content: center;
}
.vectorBox {
  width: 100% !important;
}
.vectorBox .img-box {
  padding: 8px 10px;
  text-align: center;
  background: #f7f7f7 ;
  border-radius: 5px;
  margin-bottom: 10px !important;
}

.vectorBox .img-box img {
  height: 40px;
    width: 40px;
    object-fit: contain;
}

.vectorBox .campName p{
  margin-bottom: 0px;
  font-size: 12px;
  color: #9c9c9c;
}

.simselectCard .active_cl {
  border: 3px solid #266fd6;
}

