.leftbar {
	width: 250px;
	height: 100%;
	position: fixed;
	background: #0E2954;
	border-right: 1px solid rgba(0, 0, 0, 0.03);
	z-index: 9;
	overflow-y: auto;
	transition: all 0.3s ease;
}

.leftbar .sub-menu button {
	color: #b3d0ff;
	font-size: 12px;
	width: 100%;
	text-align: left;
	display: flex;
	position: relative;
	align-items: center;
	border: none !important;
	padding: 7px 10px 7px 20px;
	background-color: transparent;
}

.leftbar .sub-menu button:hover {
	color: #b3d0ff;
}

.leftbar .down-arrow {
	font-size: 19px !important;
	position: absolute;
	right: 6px;
	top: 7px;
}

.leftbar .sub-menu button:focus {
	outline: none;
	color: #b3d0ff;
}

.leftbar.shrink {
	width: 80px;
	transition: all 0.3s ease;
}

.leftbar.shrink span {
	display: none;
}

.leftbar.shrink .nav-bar .sidebar-list .icon {
	font-size: 12px;
}

.leftbar.shrink .sidebar-logo .logo {
	display: none;
}

.leftbar.shrink .sidebar-logo .miniLogo {
	display: block !important;
}

.leftbar.shrink .sidebar-logo .miniLogo img {
	margin: 20px !important;
	height: 30px !important;
}

.leftbar.shrink .sidebar-logo {
	padding: 0px !important;
}

.leftbar.shrink .sidebar-list li,
.leftbar.shrink .sub-menu button {
	padding-left: 0px;
	text-align: center;
	display: block;
}

.leftbar.shrink .sidebar-list li a {
	padding-left: 0px;
	display: contents;
}

.sidebar-list {
	list-style: none;
	padding: 0px;
}

.sbheading {
	color: #fff;
	padding-left: 20px;
	line-height: 15px;
	width: 100%;
	display: block;
	font-size: 10px;
	padding-top: 15px;
	text-transform: uppercase;
	font-weight: 600;
	margin-bottom: 8px;
}

.nav-bar .sidebar-list li {
	position: relative;
	line-height: 30px;
	width: auto;
}

.nav-bar .sidebar-list li:last-child {
	border-bottom: none;
}

.nav-bar .sidebar-list li a {
	padding: 5px 10px 4px 19px;
	color: #b3d0ff;
	font-size: 12px;
	text-decoration: none;
	align-items: center;
	width: 100%;
	position: relative;
	font-weight: 400;
	cursor: pointer;
	display: flex;
}

/* .nav-bar .sidebar-list li a:hover{
    color: #fff;
} */
.nav-bar .sidebar-list .icon {
	vertical-align: middle;
	font-size: 17px;
	margin-right: 8px;
}

.sidebar-logo {
	display: flex !important;
	align-items: center !important;
	justify-content: space-between !important;
	width: 100%;
	background: #213a61;
	height: 64px;
	padding-left: 18px;
	margin-bottom: 20px;
	position: relative;
}

.sidebar-logo img {
	height: 40px;
}

.templates {
	list-style: none;
}

.campaign-nav {
	border: none;
	background: #5975e1;
	margin-left: 20px;
	margin-top: 135px;
	height: 45px;
	width: 195px;
}

.template-nav {
	list-style: none;
}


.nav-bar li.active a {
	background-color: hsla(0, 0%, 100%, .08);
	transition: all .3s ease;
	padding: 5px 10px 4px 15px !important;
	font-weight: 500 !important;
	color: #000 !important;
	opacity: 1 !important;
	color: #fff !important;
	border-left: 4px solid #d8d8d8;
	padding-left: 15px !important;
}

.camp-btn {
	margin-top: 100px;
	position: absolute;
	left: 0px;
	right: 0px;
	padding-left: 0px !important;
}

.leftbar::-webkit-scrollbar {
	width: 7px;
	background-color: #f5f5f5;
}

.leftbar::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #2a3042;
}

.leftbar::-webkit-scrollbar-thumb {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #d0d0d0;
	border-radius: 10px;
}

.activeCollapse .down-arrow {
	transform: rotate(90deg);
	transition: transform 0.25s;
}

.deactiveCollapse .down-arrow {
	transition: transform 0.25s;
}

.leftbar .accordion-li li a {
	color: #7b8ec2;
}


.leftbar.shrink .accordion-li li span {
	display: block;
	font-size: smaller;
}

.shrink .accordion-li li.active a {
	width: 100%;
}

.leftbar.shrink .accordion-li a {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;

}

.leftbar.shrink .nav-bar li a {

	margin: auto;
}

.leftbar.shrink .nav-bar li {
	margin: auto;
}

.leftbar.shrink .nav-bar li a span {
	/* background-color: #000; */
	padding-left: 0px !important;
}

.leftbar.shrink .nav-bar li.active a {
	/* background-color: #000; */
	width: 100%;
}