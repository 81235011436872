#product-container .bg-blue{
   
    background: linear-gradient(to right, #2A3042, #2D4C6E);
    height: 195px;
    width: 100%;

}
#product-container  .white-main-container{
    margin-top: -148px;
    background: #fff;
    margin-left: 25px;
    margin-right: 25px;
    border-radius: 20px;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
}
.guide-header{
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    border-bottom: 1px solid #ececec;
}
.guide-header .round-btn{
    border: none;
    border-radius: 19px;
    padding: 9px 25px 11px;
    font-size: 15px;
    font-weight:600;
}

.guide-header .dark-blue{

    background: #2B3A52;
    color: #fff;
    text-transform: uppercase;

}  
.guide-header .guide-logo{
    width: 143px;

}
.tab-container-wrap{

}
.l-col{
    border-right: 1px solid #ececec;
    height: 728px;
}
.l-col.nav-pills .nav-link{

    color: black;
    font-weight: 600;

}
.l-col.nav-pills .nav-link.active, .l-col.nav-pills .show > .nav-link{
    background-color: #fff;
    border-radius: 0px;
    border-right: 2px solid #0B6AFC;
    color: black;
    font-weight: 600;
    font-size: 14px;
    padding-top: 0px;
}
.l-col.nav-pills .nav-link{
    color: black;
    font-weight: 600;
    font-size: 14px;
    padding-top: 0px;
    background-color: #fff;
    border-radius: 0px;
}
.l-col.nav-pills .nav-wrap{
    display: flex;
    align-items: center;   
}

.l-col.nav-pills .nav-link.active .nav-wrap, .l-col.nav-pills .show > .nav-link .nav-wrap{
    padding:10px;
    background-color: #F3F9FF;
    border-radius: 10px;

}
.l-col.nav-pills .nav-link .nav-wrap{
    padding:10px;
   

}

.l-col.nav-pills .nav-wrap .nav-icon{
    width: 35px;
}
.l-col.nav-pills .nav-link .nav-div p{
    font-size: 10px;
    margin-bottom: 0px;
    color: rgb(85, 83, 83);
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
}
.guide-tab-wrap .tab-wrapper{
    background: #fff;
    padding: 26px;
    border-radius: 10px;
    /* box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%); */
    border: 1px solid #e8eaec;
    height: 615px;
    overflow-y: auto;
}

.tab-wrapper .tab-header{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.tab-wrapper .tab-header .tab-icon{
    width: 30px;
}
.tab-wrapper .tab-header h5{
    font-size: 23px;
    font-weight: 700;
    margin-left: 7px;
    margin-bottom: 0px;
    text-transform: uppercase;
}
.tab-content.guide-tab-wrap{
    background: #F9FAFC;
    padding: 18px 63px 18px 20px;
}
.tab-wrapper .head-one{
    border-left: 4px solid black;
    padding: 5px;
    font-weight: 600;
    margin-bottom: 15px;
}
.tab-wrapper p{
    font-size: 14px;
}
.tab-wrapper .light-blue-bg{
    background: #E9F5F4;
    height: 145px;
    border-radius: 13px;
}
.tab-wrapper .dash-box{
    box-shadow: 0 0 40px 0 rgb(0 0 0 / 10%);
    padding: 10px;
    text-align: center;
    border-radius: 13px;
    min-height: 134px;
    margin-top: -91px;
    background: #fff;
    margin-bottom: 31px;
}
.tab-wrapper .dash-box h6{
    font-weight: 600;
    font-size: 14px;
    margin-top: -8px;
}
.tab-wrapper .dash-box p{
    font-size: 12px;
    margin-bottom: 0px;
}
.tab-wrapper .tab-font{
    background: #2b3a52;
    color: #fff;
    height: 35px;
    width: 35px;
    padding: 7px;
    border-radius: 31px;
    margin-top: -50px;
}
.tab-wrapper .dash-col{
    flex: 0 0 19%;
    max-width: 19%;
}
.tab-wrapper .light-blue-bg h6{

    font-weight: 600;
    border-left: 4px solid black;
    padding: 4px;

}
.tab-wrapper .light-blue-bg p{
    margin-bottom: 0px;
    font-size: 13px;
}
.tab-wrapper .img-1{
    width: 80%;
    margin-top: -36px;
}
.tab-wrapper .img-2{
    margin-bottom: -48px;
    width: 84%;
}
.tab-wrapper .img-3{
    width: 85%;
    margin-top: -21px;
}
.tab-wrapper .second-box h6{
    margin-top: 40px;
}
/* ---------------Custom Timeline CSS---------------- */
.custom-timeline{
    width: 100%;
    display: flex;
    list-style: none;
    padding-left: 0px;
}
.step-timeline{
    float: left;
    display: flex;
    align-items: center;
    position: relative;
    width: 33.3%;
    font-size: 13px;
    cursor: pointer;
}
.step-timeline div{
    background: #ccced2;
    width: 35px;
    height: 35px;
    border-radius: 20px;
    padding: 6px;
    text-align: center;
    color: #2b3a52;
    margin-right: 7px;
}
.step-timeline::after{
    content: "";
    border-bottom: 2px solid #ccced2;
    width: 65px;
    position: absolute;
    margin: 2px 0 0 12px;
    right: 12px;
}
.step-timeline:last-child::after{
    content: none;
}
.step-timeline.active div{
    background: #2d4c6e;
    color: #fff;
}

.step-timeline.stepper{
    width: 100%;
}
.light-blue-bg.right-block h6{
    border-left: 0px;
    border-right: 4px solid black;
}
.guide-footer{
    height: 75px;
    background: #fff;
    border-top: 1px solid #ececec;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 25px;
}
#left-tabs-example-tabpane-third .step-timeline::after{
    width: 25px;
}
.step-timeline-stepper{
    position: relative;
}

.guide-footer .r-count, .guide-footer .l-count{    
    display: flex;
    align-items: center;
    cursor: pointer;
}
.guide-footer .r-count{
    position: absolute;
    right: 52px;
}
.guide-footer .foot-icon {
    width: 32px;
    margin-right: 10px;
}
.guide-footer .l-count .foot-icon{
    margin-left: 20px;
}
.guide-footer .foot-text{
    font-size: 14px;
    margin-right: 20px;
}
.guide-footer p{
    margin-bottom: 0px;
    font-size: 11px;
    color: #686a6b;
    line-height: 10px;
}
button:focus{
    outline: none !important;
}
.popover{
    padding: 10px !important;
    font-size: 11px !important;
}
.main-footer .mid-head{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.main-footer .mid-head a{
    color: black;
    font-size: 14px;
}
.main-footer p{
    margin-bottom: 0px;
    font-size: 14px;

}
.main-footer{
    margin-top: 40px;
    background: #F6F6F6;
    padding: 20px;
}