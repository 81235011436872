.modal-open {
  padding: 0px !important;
  padding-right: 0px !important;
}

.modal.fade:not(.in).right .modal-dialog {
  -webkit-transform: translate3d(25%, 0, 0);
  transform: translate3d(25%, 0, 0);
}
.modal.right.fade .modal-dialog {
  right: 0px;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.info-filter .modal-dialog {
  height: 100%;
  width: 800px !important;
  position: fixed;
  z-index: 1001;
  top: 0;
  right: 94px !important;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s !important;
  margin: 0px !important;
  right: 174px !important;
  max-width: 800px;
}
.info-filter .modal-header .modal-title {
  font-size: 17px;
  color: #403e3e;
}

.info-filter .modal-content {
  border-radius: 0px !important;
}
/* .info-filter .modal-header .close {
	padding: 10px !important;
} */
.info-filter .modal-header .close:focus {
  outline: none;
}

.upload-block {
  border: 1px dashed #c5bcbc;
  padding: 45px 35px;
  text-align: center;
  margin: 30px 0px;
}
.upload-block .upload-icon {
  color: #0573f7;
  font-size: 29px;
}
.upload-block .blue-text {
  color: #0573f7;
  font-size: 16px !important;
}
.upload-block h6 {
  font-size: 17px;
  font-weight: 600;
  margin-top: 10px;
}
.upload-block p {
  margin-bottom: 0px;
  font-size: 14px;
  color: #313233;
}

/* ---------------------
        Tree CSS
------------------------ */
.rct-checkbox {
  padding: 0px !important;
}
.react-checkbox-tree button {
  padding-right: 4px !important;
}
.margin-l {
  margin-left: -7px !important;
}

.add-user-btn {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-right: 45px;
  background: #ffffff;
  padding: 0.55rem 2.2rem;
  box-shadow: 8px 2px 10px -4px rgb(0 0 0 / 75%);
  width: 100%;
  transform: translateX(-18px);
}
.info-filter .table {
  max-height: 600px;
  overflow-y: scroll;
}

.info-filter .table thead {
  position: sticky;
  inset: 0;
  background: white;
  /* border-bottom: 2px solid currentColor; */
  width: 100%;
  top: -1rem;
  z-index: 999;
  box-shadow: 0px 2px 9px -10px rgb(0 0 0 / 75%);
}

.info-filter .modal-body {
  /* max-height: 600px; */
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.info-filter .modal-body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.info-filter .modal-body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.addUserDetail h6 {
  color: #1967d2;
  margin: 15px 0;
  font-size: 13px;
}
