.back_auth {
    display: block !important;
    margin-bottom: 20px;
    font-weight: 600;
    float:right !important;
}

.auth_ver_mobile {
    margin-bottom: 20px;
}

.auth_steps {
    padding-left: 0;
}

.auth_steps li {
    list-style: none;
    margin-bottom: 20px;
}