.label-input {
    width: 100%;
}

#billing-card {
    background: #fff;
}

.cardInputElement {
    display: none;
}

#billing-card .line {
    display: block;
    width: 50px;
    height: 2px;
    background: #0d78f9;
    margin-bottom: 10px;
}

.cardInput {
    margin: 10px;
    padding: 25px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, .11), 0 0 1px 0 rgba(0, 0, 0, .1);
    min-height: 320px;
    text-align: center;
}

.cardInput:hover {
    cursor: pointer;
}

.cardInputElement:checked+.cardInput {
    box-shadow: 0 0 2px 1px #306899
}


.panel-heading {
    font-size: 21px;
    text-align: center;
    color: #2a3042;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 1px;

}

.circle-check {
    font-size: 19px !important;
    color: #4aa746;
    margin-right: 10px;
}

.panelBody {
    padding: 0px 16px;
    position: relative;

}

.panelBody h4 {
    font-size: 35px;
    color: #2a3042;
    margin-bottom: 10px;
}

.panelBody ul {
    list-style: none;
    padding: 0px;

}

.panelBody ul li {
    padding: 10px 0px;
    border-bottom: 1px dotted #f2f2f2;
    color: #4d515a;
}

.panelBody .btn-choose {
    position: absolute;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    border-radius: 28px;
    padding: 10px 30px;
    bottom: -85px;
    background: #2a3042;
    border: #2a3042;
}

.top-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F2F7FE;
    border-radius: 12px;
    padding: 20px;
    min-height: 127px;
    margin-bottom: 25px;
}
.top-wrap h5{
    font-weight: 600;
}

.top-wrap .current-plan h6 {
    color: #6b6d6f;
}

.top-wrap .current-plan p {
    font-size: 25px;
    font-weight: 600;
}

.top-wrap .icon {
    height: 52px;
}

.top-wrap ul {
    font-size: 14px;
    color: #48494c;
    margin-bottom: 0px;
}



.top-wrap .progress {
    height: 5px !important;
}

#billing-card .list-li{
    list-style: none;
    padding: 0px;
    margin-bottom: 0px;
}
#billing-card .list-li li{
    line-height: 13px;
    font-size: 15px;
    margin-left: 31px;
    margin-bottom: 11px;
}
#billing-card .list-li .list-icon{
    color: #4aa746;
    width: 23px;
    height: 23px;
    margin-right: 8px;
    margin-left: -31px;
}