#graph-wrap .card-wrap .rating-num h3{
    font-size: 50px;
    margin-bottom: 0px;
    font-weight: 600;
    color: #4fc68c;

}
#graph-wrap .card-wrap .rating-num .rate-text{
    margin-bottom: 0px;
    font-size: 12px;
    margin-left: 10px;
}

#graph-wrap .card-wrap .rating-num .para{
    margin-bottom: 0px;
    font-size: 11px;
    color: #8e9297;
}
#graph-wrap .card-wrap .rating-num .green-icon{
    color: #ffffff;
    background: #4fc68c;
    border-radius: 5px;
}   

#graph-wrap .card-wrap .rating-num .border-bottom{
    padding-bottom: 12px;
}
#graph-wrap .card-wrap .rating-num .line-graph{
    width: 112px;
}
#graph-wrap .card-wrap .rating-num .green-arrow{
    color: #ffffff;
    background: #4fc68c;
    border-radius: 15px;
    font-size: 14px;
}

#graph-wrap .card-wrap .rating-num .green-text{
    color: #4fc68c;
    font-size: 11px;
}
#graph-wrap .card-wrap .bottom-wrap{
    border: 1px solid #d2d2d2;
    border-radius: 10px;
    margin-top: 10px !important;
    margin-bottom: 15px !important;
}
#graph-wrap .card-wrap .bottom-wrap p{
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 11px;
    color: #8e9094;
    font-weight: 600;
    margin-bottom: 0px;
}
#graph-wrap .card-wrap .bottom-wrap h5{

    font-size: 29px;
    margin: 5px 0px;

}
#graph-wrap .card-wrap .dot{

    display: block;
    height: 12px;
    width: 12px;
    background: #6E67D3;
    border-radius: 3px;
    margin-right: 7px;

}
#graph-wrap .card-wrap .dot.greend{
    background: #4FC68C;
}
#graph-wrap .card-wrap .dot.blue{
    background: #418AFA;
}
#graph-wrap .card-wrap .border-l-r{
    border-left: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
}
#graph-wrap .card-wrap .progress{
    height: 3px;
    margin-bottom: 15px;
    margin-top: 10px;
}
#graph-wrap .card-wrap .bg-voilet.progress .progress-bar{
    background: #6E67D3;
}
#graph-wrap .card-wrap .bg-green.progress .progress-bar{
    background:#4fc68c ;
}
#graph-wrap .card-wrap .bg-blue.progress .progress-bar{
    background: #418AFA;
}
#graph-wrap .avg-block.offenders h5{
    font-size: 22px;
    margin-right: 17px;
}
#graph-wrap .avg-block.offenders #company, #graph-wrap .avg-block.offenders #industry {
    margin-top: -36px;
}