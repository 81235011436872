@import url("https://use.typekit.net/slg0ndf.css");

body {
  background: #fff !important;
  font-family: "Muli", sans-serif !important;
}

.btn {
  padding: 0.375rem 1rem !important;
  font-size: 12px !important;
}
.containerbar {
  position: relative;
  /* height: 100%; */
}
.btn-secondary{
  color: #495057 !important;
  background: #e2e5ed !important;
  border-color: #e2e5ed !important;
}

.btn-primary {
  color: #fff !important;
  background: #1967d2 !important;
  border-color: #1967d2 !important;
}

.form-control:focus {
  box-shadow: unset !important;
  border-color: unset !important;
}

.btn:focus,
.btn.focus {
  box-shadow: unset !important;
}

.form-check {
  padding-left: unset !important;
}

/* --------------Badge CSS---------- */
.badgeBtn {
  font-size: 10px !important;
  padding: 6px 10px !important;
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}

.badge-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
}
.badge-success {
  color: #fff !important;
  background-color: #28a745 !important;
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-primary {
  color: #fff;
  background-color: #0e2954;
}
.font-size-12 {
  font-size: 12px !important;
}
.badge-soft-success {
  color: #34c38f !important;
  background-color: rgba(52, 195, 143, 0.18);
}
.badge-pill {
  padding: 6px 10px !important;
  font-size: 12px !important;
  border-radius: 10rem !important;
}
.badge-soft-danger {
  color: #f46a6a !important;
  background-color: rgba(244, 106, 106, 0.18);
}
.badge-soft-warning {
  color: #f1b44c !important;
  background-color: rgba(241, 180, 76, 0.18);
}
.badge-soft-primary {
  background-color: rgba(85, 110, 230, 0.25) !important;
  color: #556ee6 !important;
}
.badge-soft-secondry {
  background-color: rgb(154 241 208 / 15%) !important;
  color: #75e79b !important;
}
.main-content {
  margin-left: 250px !important;
  overflow: hidden;
  min-height: 500px;
  position: relative;
  transition: all 0.3s ease;
}
.main-content.expand {
  margin-left: 80px !important;
}
.main-content.expand .fixed-top {
  left: 80px !important;
  transition: all 0.3s ease;
}

.primaryBadge {
  border-radius: 3px !important;
  color: #1967d2 !important;
  background: #d7e7ff !important;
  padding: 2px 10px !important;
  font-size: 10px !important;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  border: 1px solid #b8daff !important;
}

/* .content-wrap {
  margin-top: 75px;
} */

/* common class in body */

.viewCss{
  color: #1867d2 !important;
  cursor: pointer !important;
}

.fixcalcHeight{
  height: calc(100vh - 234px);
  overflow: auto;
  background-color: #fff;
}

.previewBtn {
  font-size: 12px !important;
  cursor: pointer !important;
  color: #1867d2 !important;
}

.form-check-input {
  position: unset !important;
}

.tempGrids .tempCard {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}
.tempGrids .row {
  margin: 0px -10px;
}

.content-wrap {
  margin-top: 60px;
}

.contentbar {
  background-color: #fff;
  padding: 15px;
}

.contentbar .tableContainer .card-body {
  padding: 0px !important;
}

.tableContainer .td-style {
  color: #48555d;
  vertical-align: middle;
  font-size: 12px;
}

.tableContainer .right-arrow {
  font-size: 20px;
}

.badge {
  padding: 6px 10px !important;
  border-radius: 3px !important;
  text-transform: uppercase;
}

.secondaryBadge {
  background: #e9ecef;
  color: rgba(39, 44, 51, 0.7) !important;
}

.primaryBadge {
  color: #1967d2 !important;
  background: #d7e7ff;
}

.dropdownIcon .dropdown-item {
  font-size: 12px;
  padding: 4px 16px;
}

.dropdownIcon .btn-primary {
  background-color: unset !important;
  border-color: unset !important;
}

.unserLinecss{
  border-top: 3px dotted rgb(206 212 218);
  margin: 18px 0px;
}

/*******************************
Common Table class
********************************/
.table tbody + tbody{
  border-top: 1px solid rgba(0, 0, 0, 0.03) !important;
}

.commonDflex {
  display: flex;
  align-items: center;
}


.appTable h6{
  margin-bottom: 2px;
  color: black;
  font-size: 13px !important;
}

.appTable span{
  color: #b1aeae;


}

.tableContainerWrapInner .td-center {
  text-align: center;
}

.tableContainerWrapInner .th-30 {
  width: 30%;
}

.tableContainerWrapInner {
  border: 1px solid #e2e5ed;
}

.tableContainerWrapInner tr:last-child {
  border-bottom: 1px solid #fff;
}

.tableContainerWrapInner .table thead tr th {
  font-size: 11px;
  background: #f6f6f6;
  padding: 10px 15px !important;
  text-transform: uppercase;
  border-top: none;
}



/* .tableContainerWrapInner .table tbody tr td {
  font-size: 12px;
  padding: 12px 15px;
  vertical-align: middle;
} */


.tableContainerWrapInner .table tbody tr td{
  font-size: 12px !important;
  padding: 12px 15px !important;
  vertical-align: middle !important;
  font-weight: 400 !important;
}


.tableContainerWrapInner tr {
  vertical-align: baseline;
}

.tableContainerWrapInner .table {
  margin-bottom: 0;
}


.tableContainerWrapInner td .circle{
  color: #1967d2;
  vertical-align: middle;
  text-decoration: none;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  justify-content: center;
}


.tableContainerWrapInner tr td:last-child {
  border-bottom: 1px solid #fff;
}



.tableContainerWrapInner .flexTableData {
  display: flex;
  align-items: center;
}

.tableContainerWrapInner .flexTableData .circle{
  margin-right: 15px !important;
}

.tableContainerWrapInner .flexTableData .companyLogoIcon {
  margin-right: 15px;
}

.tableContainerWrapInner .flexTableData h6 {
  margin-bottom: 5px;
  font-size: 13px;
}
.tableContainerWrapInner .flexTableData span {
  color: #b1aeae;
}

.tableContainerWrapInner img {
  color: transparent;
  padding: 5px;
  background: #f6f6f6;
  border-radius: 10px;
  width: 70px;
  height: 50px;
  object-fit: cover;
}

.tableContainerWrapInner .yes-badge {
  color: #28a745;
}

.tableContainerWrapInner .no-badge {
  color: red;
}

.tableContainer {
  box-shadow: 0 3px 3px -2px rgba(39, 44, 51, 0.1),
    0 3px 4px 0 rgba(39, 44, 51, 0.04), 0 1px 8px 0 rgba(39, 44, 51, 0.02);
  background-clip: border-box;
  border: 1px solid #dfe2e6;
  border-radius: 0.5rem;
  background-color: #fff;
}

.tableContainer .TableHead-bgColor {
  text-align: center;
  padding: 8px 10px;
  background: #f6f6f6 !important;
}

.tableContainer .active.card-header {
  border: unset;
}

.tableContainer .th-heading {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.timeBox span {
  border-left: 1px solid #adb5bd;
  padding-left: 5px;
  margin-left: 5px;
}

/*******************************
End
********************************/

.campListGrids .card-body {
  padding: unset !important;
}

/*******************************
Breadcrumbbar
********************************/
.breadcrumbbar .flexRow {
  align-items: center;
  width: 100%;
  margin: 0;
}

.breadcrumbbar .flexFilterButtons {
  display: flex;
  justify-content: end;
  padding: 0px !important;
}

.breadcrumbbar {
  padding: 10px 15px;
  height: 52px;
  display: flex;
  border-bottom: 1px solid #e2e5ed;
}

.breadcrumbbar .page-title {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
}

.breadcrumbbar .bar_Icons {
  background: #f8f9fc;
  color: #000;
  cursor: pointer;
  padding: 7px 0px 6px 6px;
  border-radius: 51%;
  border: 1px solid #ced4da;
  font-size: 11px;
}

.breadcrumbbar .flexRow>.col-md-4{
  padding: 0 !important;
}
/*******************************
end
********************************/

/*******************************
Filter Wrap
********************************/
.textMuted {
  font-size: 12px;
  color: #828282;
  margin-bottom: 0;
}
.filter-wrapper {
  border-bottom: 1px solid #e2e5ed;
  padding: 0 15px;
  background: white;
  height: 39px 
}

.filter-wrapper .alignItemsCenter {
  align-items: center;
  margin: 0;
}

.filter-wrapper .rightFilters {
  display: flex;
  align-items: center;
  justify-content: end;
}

.filter-wrapper .positionSearchIcon {
  position: relative;
}

.filter-wrapper .positionSearchIcon .inputSearch {
  background-color: #f6f6f6;
  border: unset;
  border-top: none;
  border-bottom: none;
  border-radius: 0;
  height: 35px;
  padding-left: 30px;
}

.filter-wrapper .input-group {
  font-size: 12px !important;
  background-color: #f6f6f6;
  width: 234px !important;
  border-left: 1px solid #e2e5ed;
  border-right: 1px solid #e2e5ed;
}

.filter-wrapper .form-group {
  margin-bottom: 0;
}
.filter-wrapper select {
  border: unset;
  font-size: 13px !important;
  cursor: pointer;
  padding: 0px 12px !important;
}

.filter-wrapper .positionSearchIcon svg {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 13px;
}

.filter-wrapper .positionSearchIcon .inputSearch:focus {
  box-shadow: none !important;
  z-index: 0 !important;
}

.filter-wrapper select:focus {
  box-shadow: unset;
}

.filter-wrapper .refreshBtn {
  background-color: unset;
  color: black;
  border: unset !important;
}

.filter-wrapper .refreshBtn:hover {
  background-color:#1967d2 !important;
  color:white !important;
}

.filter-wrapper .sort-filter-btn {
  border-radius: 3px;
  font-size: 12px;
  padding: 6px 10px;
  cursor: pointer;
}

.filter-wrapper .sort-filter-btn .toggleTraining p {
  margin-bottom: 0px;
}

.filter-wrapper .sort-filter-btn .toggleTraining {
  align-items: center;
}

.filter-wrapper .sort-filter-btn .filter-btn {
  font-weight: 400;
  color: #212529;
}

.filter-wrapper .positionSearchIcon h6 {
  font-size: 12px !important;
  margin-bottom: 0 !important;
  color: #949494 !important;
  padding: 8px 0px !important;
}

/*******************************
End
********************************/

/*******************************
common Button
********************************/
.btn {
  border: unset !important;
  border-radius: 6px !important;
  padding: 7px 15px !important;
  font-size: 12px !important;
}
.btn-trans {
  color: #0049b0 !important;
  text-transform: uppercase !important;
  font-size: 11.5px !important;
  font-weight: 600 !important;
  background: unset !important;
  border: unset !important;
}

.tiIcon {
  margin-right: 5px;
}

.btn-success {
  color: #fff !important;
  opacity: unset !important;
  background-color: #198754 !important;
  border-color: #198754 !important;
}

.disable-primary-btn {
  background-color: unset !important;
  border: none !important;
  color: black !important;
}

.closeBtn {
  color: #495057 !important;
  background: #e2e5ed !important;
  border-color: #e2e5ed !important;
}

.darkBtn {
  color: #1b1e21 !important;
  background-color: #d6d8d9 !important;
}

.readBtn {
  padding: 0px !important;
  font-size: 14px;
}

.commonrefreshBtn{
  background: #d7e7ff !important;
  color: blue !important;
  padding: 7px 10px !important;
}

.commonrefreshBtn .btn-icon{
margin-right: 0px !important;
}
/*******************************
End
********************************/

/*******************************
 Common Modal
********************************/

.form-control {
  font-size: 13px !important;
  height: 38px !important;
  color: #7a7a7a !important;
  border-radius: 6px !important;
}

input::placeholder {
  font-size: 13px !important;
  color: #7a7a7a !important;
}


.modal-header {
  display: flex !important;
  justify-content: space-between !important;
  border-bottom: 1px solid #e2e5ed !important;
  padding: 10px 15px !important;
  background: #fff !important;
}
.modal-header h5 {
  font-size: 15px !important;
  font-weight: 500 !important;
  margin-bottom: 0.5rem !important;
}

.modal-header p {
  font-size: 12px;
  margin-bottom: 0;
  color: #949494;
}

.modal-header .close {
  font-size: 21px !important;
  cursor: pointer !important;
  font-weight: 400;
}

.modal-content {
  border: unset !important;
}

/* end */

/*******************************
Right Modal
********************************/

.right-modal-wrap .modal-body {
  padding: 15px !important;
}

.right-modal-wrap .modal-dialog {
  width: 600px !important;
  right: 151px !important;
}

.right-modal-wrap .modalBody {
  padding: 15px;
  height: 86vh;
  overflow-y: auto;
}

.footerModal .modalFooter {
  border-top: 1px solid #e2e5ed;
  padding: 10px 15px;
  display: flex;
  justify-content: end;
  position: absolute;
  width: 100%;
  bottom: 0;
  align-items: center;
  background: #fff;
}

.chooseModal .modal-dialog {
  max-width: 500px;
}


.mainfooterModal .modalFooter{
  border-top: 1px solid #e2e5ed;
  padding: 10px 15px;
  display: flex;
  justify-content: end;
  position: absolute;
  width: 100%;
  bottom: 0;
  align-items: center;
  background: #fff;
}

/*******************************
Pagination
********************************/
.pagination-Wrap {
  padding: 0px 15px;
  border-top: 1px solid #dfe2e6;
}

.pagination-Wrap .pagination {
  margin-bottom: 0px;
  padding: 10px 0;
}

.pagination-Wrap .footer-pg .pgCount {
  font-size: 12px;
}

.pagination-Wrap .footer-pg .pgCount p {
  margin-bottom: 0px;
}

.pagination-Wrap .pagination .page-item {
  border: none;
}

.pagination-Wrap .page-link {
  color: black;
  border: none;
  font-size: 13px;
}

.pagination-Wrap .page-item.active .page-link {
  background-color: #e9ecef;
  border-color: #dee2e6;
  color: black;
  border-radius: 5px;
}

/*******************************
End
********************************/

.btn-icon {
  font-size: 13px !important;
  margin-top: -2px;
  margin-right: 6px;
}

.mini-head {
  margin-bottom: 15px;
}

.mini-head .btn {
  font-size: 13px;
  width: auto;
  border-radius: 2px;
}
.mini-head .input-group > .form-control {
  font-size: 12px !important;
  padding-left: 33px !important;
  border-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}
.search-wrap {
  position: relative;
}
.search-wrap .search-icon {
  position: absolute;
  top: 7px;
  left: 8px;
  color: #848588;
  font-size: 20px;
  z-index: 5;
}
.breadcrumb {
  margin-bottom: 0px !important;
  background-color: transparent !important;
  padding: 0 !important;
}
.breadcrumb-item a {
  font-size: 14px !important;
  color: #6c757d;
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #2a3042 !important;
  font-size: 14px !important;
}

.card {
  border: none !important;
  /* margin: 0px 15px 50px 15px; */
}
.card-body {
  padding: 25px !important;
}

.table > :not(caption) > * > * {
  border-bottom-width: 0px !important;
}
.table {
  margin-bottom: 10px;
  color: #263a5b;
}
.table th {
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  /* border-top: 1px solid rgba(0, 0, 0, 0.03); */
  padding: 0.7rem !important;
  background-color: #f8f9fa;
}
.table thead th {
  vertical-align: middle !important;
  /* border-bottom: 1px solid rgba(155, 87, 87, 0.03) !important; */
  /* background: #2a3042; */
  color: #2a3042;
}
.table-icon {
  font-size: 15px !important;
  margin: 0 auto;
  color: #374ca7;
  cursor: pointer;
}
.table td {
  vertical-align: middle !important;
  border-top: 1px solid rgba(0, 0, 0, 0.03);
  padding: 0.7rem !important;
  font-size: 13.5px;
}
.row.bg-dark {
  background-color: #2a3042 !important;
  color: #fff;
}

/* -----------Round Action Button CSS------------- */
.btn-primary-rgba {
  background-color: unset !important;
  border: none !important;
  color: #0080ff !important;
  /* margin: 0px !important; */
}
.btn-danger-rgba {
  background-color: rgba(255, 63, 63, 0.1) !important;
  border: none !important;
  color: #ff3f3f !important;
  /* margin: 0px !important; */
}

.btn-round {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
}

.action-dropdown .dropdown-toggle::after {
  content: none !important;
}

/* .pagination {
  float: right;
} */

.Toastify__toast-container--top-right {
  top: 5em;
}

.mini-head .form-group,
table .form-group,
table .form-group .form-check {
  margin-bottom: 0px !important;
}
.form-group {
  margin-bottom: 20px;
}

/* .form-control {
  font-size: 12px !important;
  border-radius: 2px !important;
  height: 38px !important;
} */
.only-icon {
  font-size: 20px !important;
}
.p-0.card-body {
  padding: 0 !important;
}

.table-responsive {
  overflow-x: inherit !important;
}

hr {
  height: 0px !important;
}

.red-star {
  color: #c40000;
  font-size: 15px;
}
.td-ellipsis,
.td-p-ellipsis {
  width: 133px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-ellipsis {
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltip-icon {
  font-size: 18px !important;
  margin-left: 7px;
  margin-bottom: 0px;
}
.left-btn {
  position: absolute;
  left: 13px;
}

/* ----------------------
        RESPONSIVE CSS
------------------------- */

@media only screen and (min-width: 300px) and (max-width: 480px) {
}
@media (max-width: 767px) {
  .leftbar {
    position: fixed;
    margin-left: -250px;
  }
  .main-content {
    margin-left: 0px !important;
  }
  .fixed-top,
  .footer {
    left: 0px !important;
  }
}

.goog-te-banner-frame {
  display: none !important;
}

.goog-te-menu-value:hover {
  text-decoration: none !important;
}

body {
  top: 0 !important;
}

#google_translate_element2 {
  display: none;
}

.multi-select .dropdown-content {
  background: #fff;
}

.multi-select {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

.multi-select .dropdown-heading-value {
  font-size: 14px !important;
  width: 130px;
}

.create-wrap .dropdown-container {
  border-bottom: none;
}

.multi-select .dropdown-content .panel-content {
  padding: 18px;
  margin-top: -10px;
}

.multi-select
  .dropdown-content
  .panel-content
  .select-panel
  div:nth-child(1)
  input {
  border: 1px solid #ced4da;
  padding: 5px;
}

.multi-select .dropdown-content .panel-content .select-panel div:nth-child(1) {
  margin-bottom: 15px;
}

.attachmentFile {
  display: flex;
  justify-content: space-between;
  width: 45%;
  align-items: center;
}
.attachmentFile input {
  border-radius: 4px 0px 0px 4px !important;
  width: 85%;
}
.attachmentFile span {
  background: #6c757d;
  height: 35px;
  width: 62px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 1px solid #6c757d;
}

/* ----------------- */

.campListGrids .accordion .card-header {
  border: 1px solid #e2e5ed;
  font-size: 13px;
  border-radius: 5px !important;
  margin-bottom: 5px !important;
}

.campListGrids .TableHead {
  background: #f6f6f6;
  font-size: 13px;
  font-weight: 600;
  height: 50px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #e2e5ed;
  margin-bottom: 5px;
}

.campListGrids .accordion .collapse.show {
  margin-bottom: 10px;
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #e2e5ed;
  border-top: 0;
}

.campListGrids .accordion .tableData thead th {
  background: #f6f6f6;
  font-size: 13px;
  font-weight: 600;
  border-top: none;
}

.campListGrids .accordion .tableData tbody tr:nth-last-child(1) > td {
  border-bottom: none !important;
}

.campListGrids .expandGrid {
  flex: 0 0 4%;
  max-width: 4%;
  text-align: center;
  padding: 0;
}
.campListGrids .expandGrid svg {
  background: #f6f6f6;
  border-radius: 8px;
  font-size: 25px;
}

#test-result .test-result-wrap .f-toggle {
  border: 1px solid #e2e5ed;
  margin-bottom: 10px !important;
  font-size: 13px;
  border-radius: 5px !important;
  background-color: #fff !important;
  padding: 0.75rem 1.25rem;
}


.newCampCreate {
  height: calc(100vh - 205px);
  overflow: auto;
}
.newCampCreate .blockSpace{
  border-bottom: 1.5px dashed #d6d6d6;
   padding: 15px 0px;
}


.newCampCreate .campBlock {
  border-bottom: 1.5px dotted #d6d6d6;
  padding: 25px 0px;
}

.newCampCreate .compHeading {
  margin-bottom: 15px;
  font-size: 15px;
  color: #303840;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  font-weight: 600;
}

.newCampCreate .point {
  font-size: 10px;
  color: #fff;
  padding: 5px 9px;
  border-radius: 15px;
  margin-right: 10px;
  font-weight: 600;
  background: #303840;
}

.newCampCreate svg {

  font-size: 13px;
}

.newCampCreate .inputSearch {
  background-color: #f6f6f6;
  border-radius: 0;
  min-height: 35px !important;
  border: 1px solid #e2e5ed !important;
  padding-left: 30px;
}

.newCampCreate .search-wrap {
  width: 76% !important;
}

.newCampCreate .capmuserList{
  display: flex;
  align-items: center;
  justify-content: end;
}
.newCampCreate .capmuserList .btn{
  height: 35px !important;
  border: 1px solid #e2e5ed !important;
  margin-right: 10px !important;
  color: #373a4d !important;
  text-transform: capitalize !important;
  background-color: #ececeb !important;
  border-color: #e2e2e2 !important;
  box-shadow: none !important;
}

.newCampCreate .capmuserList .btn-outline-secondary{
min-height:35px !important;
} 

.newCampCreate .capmuserList .btn-outline-secondary:hover{
  background-color:unset !important;
  border-color: unset !important;
  border: 1px solid #e2e5ed !important;
}




/* ----------------------
       Filters css
------------------------- */


.filterModal .modal-dialog {
  right: 74px !important;
  width: 300px !important;
}

label {
  font-size: 13px !important;
  color: #5d5d5d !important;
}

/* .filterModal .rightFilters .form-control{
  background-color: #ffffff;
  color: #8A98AC;
  font-size: 12px !important;
  height: 38px !important;
  border-radius: 0.25rem !important;
} */

.filterModal .rightFilters .form-label {
  margin-bottom: 0.5rem;
}

.dropdown-container {
  border: 1px solid #ced4da;
  height: 38px;
  padding: 0.375rem 0.75rem;
  color: #8a98ac;
}

.filterModal .rightFilters .form-group {
  margin-bottom: 12px;
}

.filterModal .sidebarSm .modal-title h5 {
  font-size: 15px;
}

.filterModal .reset_filters_store {
  font-size: 12px;
  text-decoration: none;
  cursor: pointer !important;
}

.filterModal .reset_filters_store {
  color: #212529;
  font-weight: 600;
  font-size: 12px;
  text-decoration: none;
  cursor: pointer !important;
}

/* ----------------------
       Filters Css End
------------------------- */

/* ----------------------
      TAB CSS
------------------------- */

#tab-wrapper .nav-tabs .nav-link {
  border: unset;
  text-transform: capitalize !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

#tab-wrapper .nav-tabs .nav-link.active {
  background: #fbfbfb !important;
  color: #0049b0 !important;
  border: none;
  color: #1967d2;
  border-bottom: 2px solid #1967d2 !important;
  border-radius: unset !important;
  padding-bottom: 5px !important;
  padding: 8px 16px;
  border-top: unset;
}

#tab-wrapper .nav-tabs {
  border-top: 1px solid #e2e5ed ;
  padding: 0 15px !important;
}

#tab-wrapper .nav-tabs .nav-link:hover {
  color: #5d5e63;
  border-bottom: unset;
}

#tab-wrapper .tab-wrap h5 {
  font-size: 19px;
  color: #2a3042;
  margin-top: 0.5rem;
}
/* ----------------------
      TAB CSS END
------------------------- */

.borderDashed {
  border-bottom: 1.5px dashed #e2e5ed;
  margin-bottom: 12px;
}

.cmpaigntemplateBox {
  height: calc(100vh - 175px);
  overflow: auto;
  background-color: #fff;
  padding: 15px;
}

.autoCheckbox {
  display: flex;
}

.autoCheckbox .form-check-input {
  width: 13px;
  top: -1px;
}

.cmpaigntemplateBox .tooltip-icon {
  margin-left: 7px !important;
  margin-bottom: 0px;
  color: #0c0b0b !important;
  background: #e2e5ed;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  padding: 2px;
  cursor: pointer;
}

/* ----------------------
  Common footer
------------------------- */

.capFooter {
  border-top: 1px solid #ececec;
  padding: 15px;
  margin-left: 0px;
  width: 100%;
}

/* ----------------------
 Check Box Css
------------------------- */

.checkBoxWrap .form-group {
  display: flex;
  align-items: center;
}

.checkBoxWrap .form-check-input {
  margin-left: unset !important;
  position: unset !important;
  margin: 0px 8px;
}

.checkBoxWrap .form-check {
  display: flex;
  align-items: center;
}

.checkBoxWrap .tooltip-icon {
  margin-left: 7px !important;
  margin-bottom: 0px;
  color: #0c0b0b !important;
  background: #e2e5ed;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  padding: 2px;
  cursor: pointer;
  left: 145px !important;
}

.checkBoxWrap p {
  padding-left: 20px;
  font-size: 11px !important;
  margin-bottom: 0 !important;
  color: #949494 !important;
}
