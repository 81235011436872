#evs-detail .user-card{
position: relative;
display: flex;
background: #fff;
border-radius: 20px;
color: #101a3f;
align-items: center;
justify-content: space-between;
padding-top: 18px !important;
padding-bottom: 12px !important;

}

#evs-detail .user-card p{
    margin-top: -16px;
}
#evs-detail .user-card h3{
text-transform: capitalize;
font-size: 23px;
font-weight: 600;
}
#evs-detail .user-card h5{
    font-size: 17px;
}
#evs-detail .card.blue-card{
    background: transparent;
    min-height: 153px;
}
#evs-detail .card{
    margin: 0px 0px 30px 0px;

}
#evs-detail .emp-logo{
    height: 232px;
    width: 115%;
    margin-top: -51px;
    /* object-fit: cover; */
    margin-left: -29px;
}
/* #evs-detail .th-icon{
    background: #f2f2f2;
    height: 42px;
    padding: 10px;
    border-radius: 30px;
} */
#evs-detail .evs-body p{
    margin-bottom: 0px;
    font-size: 13px;
}
#evs-detail .evs-body{
    height: 68px;
}

#evs-detail h6 {

    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
}
#evs-detail .user-img{
    background: #f2f2f2;
    height: 44px;
    border-radius: 32px;
    padding: 4px;
}
#evs-detail .last-card{
    margin-bottom: 100px;
}
#evs-detail .chart-card{
    min-height: 185px;
    border-radius: 30px;
}
#evs-detail .chart-card p{
    margin-bottom: 0px;
    font-size: 13px;
    text-align: center;
}  

#evs-detail .camp-card{
    height: 425px;
    overflow: auto;
    padding-right: 8px;
}
#evs-detail .camp-card::-webkit-scrollbar {
    width: 7px;
	background-color:#d7dee4;
    border-radius: 20px;
}
#evs-detail .camp-card::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(185, 183, 183, 0.3);
    background-color: #e7e7e9;
    border-radius: 20px;
    
}
#evs-detail .camp-card::-webkit-scrollbar-thumb
{
	-webkit-box-shadow: inset 0 0 6px rgba(182, 181, 181, 0.3);
    background-color: #d0d0d0;
    border-radius: 20px;

}
#evs-detail .evs-card{
    padding-right: 0px !important;
}
.evs-icon{
    color: #343A40;
    background-color: #CCCECF;
    padding: 5px;
    border-radius: 5px;
    margin-right: 9px;
}
#evs-detail .back-btn{
    right: -17px;
    top: -12px;
    position: absolute;
    border: none;
    cursor: pointer;
    z-index: 100;
    color: white;
    background-color: rgb(119, 119, 119);
}

.evsDetails .gridfirstRow{
    min-height: 360px;
}
.evsDetails .userDetails{
    background: #fbfbfb !important;
}

.evsDetails .userDetails{
    padding: 0px !important;
    min-height: 437px !important;
    position: relative;
}

.evsDetails .userDetails .infocardBody{
    background: white !important;
}

.evsDetails .userDetails .cardHeader{
    padding: 10px;
}

.evsDetails .setPadding{
    padding: 0px 4px 0px 4px
}

.evsDetails .phishingBox{
    border: 1px solid #efefef !important;
}

.evsDetails .infoCard{
    background: #fff;
    border: 1px solid #e2e5ed;
    border-radius: 5px;
    padding: 10px;
    position: relative;
    margin-bottom: 8px;
}

.evsDetails .cardHeader{
    width: 100%;
    z-index: 100;
    padding: 5px 0 10px;
    position: relative;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
     border-bottom: 1px solid #e2e5ed;
}

.evsDetails .circleName{
    display: inline-block;
    background-color: #e2e5ed;
    border-radius: 50%;
    margin-bottom: 10px;
}

.evsDetails .nameinfoBox{
    text-align: center;
    margin-bottom: 15px;
}

.evsDetails .nameinfoBox h5{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 2px;
}

.evsDetails .nameinfoBox p{
    margin-bottom: 0px;
    font-size: 12px;
    color: #949494;
}

.evsDetails .nameinfoBox .circleName p{
    color: black;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    height: 65px;
    width: 65px;
    font-size: 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: center;
    margin-bottom: 0px;
}



.evsDetails .infoCard h6{
font-size: 13px !important;
margin-bottom: 0 !important;
}

.centerBox{
text-align: center;
}

.contentBox .userTable{
    margin-bottom: 0px;
} 

.vltsetHeight{
height: 286px;
}

.infoCard .contentBox{
    background: #fbfbfb;
}

.contentBox .userTable tr td{
    font-size: 12px;
    padding: 6px 10px !important;
    border: unset !important;
    color: #949494;
}

.infocardBody .mainmatrixSection{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.infocardBody .mainmatrixSection .matrixBox{
    padding-right: 80px;
    border-right: 1px solid #e1e5ed;
}

.infocardBody .mainmatrixSection .matrixBox:nth-last-child(1){
    border-right: 0;
}

.infocardBody .mainmatrixSection .contFlex{
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.infocardBody .mainmatrixSection .matrixBox .contFlex img{
    width: 18px;
}

.infocardBody .mainmatrixSection .matrixBox .circleIcon{
    background: #e9ecef69;
    height: 25px;
    width: 35px;
    border-radius: 3px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}



.infocardBody .mainmatrixSection .contFlex p{
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 20px;
}

.evsDetails .infocardBody .flexTableData{
display: flex;
align-items: center;
}

.evsDetails .campIcon{
    margin-right: 10px;
}

.evsDetails .infocardBody .campIcon img{
    width: 30px;
}

.evsDetails .infoCard .infocardBody .campName{
    font-size: 12px !important;
    font-weight: 400 !important;
}

.evsDetails .infoCard .infocardBody tr td{
    font-size: 12px !important;
}

.evsDetails .infoCard .activitySection{
    display: flex;
    align-items: center;
}

.evsDetails .infoCard .activitySection .click-to-top{
    background: #e9ecef69;
    height: 28px;
    width: 28px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}

.evsDetails .infoCard .activitySection .click-to-top img{
    width: 18px;
}

.evsDetails .rightBorder{
    padding: 0px 8px;
    border-right: 1px solid #e1e5ed;
}

.evsDetails .rightBorder:nth-last-child(1){
    border-right: unset !important;
}

.evsDetails .infoCard .groupInformation{
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #e2e5ed;
}

.evsDetails .infoCard .gpinfoImg{
    background: #e9ecef69;
    height: 25px;
    width: 35px;
    border-radius: 3px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.evsDetails .infoCard .gpinfoImg img{
    width: 18px;
}

.evsDetails .infoCard .groupInformation p{
    color: #949494;
    font-size: 10px;
}


.evsDetails .table thead tr th {
    font-size: 11px;
    padding: 10px 15px !important;
    text-transform: uppercase;
    border-top: none;
}

.cardHeader .phishingBox .dropdownIcon .btn-primary{
    color: #949494 !important;
}