
.modal.suppot-modal.right.fade .modal-dialog{
    right: 105px !important;
}
.modal.suppot-modal .modal-header{
    background-color: transparent;
}
.modal.suppot-modal .modal-header h5{
    color:#2a3042;
    font-weight: 600;
}

.modal.suppot-modal.right .modal-dialog{
    width: 423px;
}
.modal.suppot-modal .custom-mode-setting {
    padding: 10px 15px;
    /* border-top: 1px solid rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.03); */
}
.modal.suppot-modal .custom-mode-setting .help-block {
    background: rgb(16 86 173 / 8%);
    padding: 15px;
    border-radius: 6px;
    margin-bottom: 20px;
}

.modal.suppot-modal .custom-mode-setting .help-block p{
    font-size: 14px;
    color: #4f4f50;
}
.modal.suppot-modal .custom-mode-setting .help-head {
    position: relative;
}

.modal.suppot-modal .custom-mode-setting .help-head h5 {
    font-size: 13px;
    background: #ffffff;
    z-index: 28;
    position: relative;
    width: 106px;
}
.modal.suppot-modal .custom-mode-setting .help-head .line {
    display: block;
    width: 100%;
    height: 1px;
    background: #efeff8;
    position: absolute;
    top: 7px;
}
.modal.suppot-modal .custom-mode-setting .block .assist-icon{
    background: #1056ad1f;
    height: 32px;
    width: 32px;
    margin-right: 10px;
    text-align: center;
    padding: 7px 0px;
    color: #0d78f9;
    border-radius: 6px
}
.modal.suppot-modal .custom-mode-setting .block a {
    text-decoration: none;
}
.modal.suppot-modal .custom-mode-setting .block h6 {
    font-size: 13px;
    margin-bottom: 3px;
    color: #000;
    font-weight: 500;
}
.modal.suppot-modal .custom-mode-setting .block p {
    margin-bottom: 0px;
    font-size: 12px;
    color: #4c4d4e;
}