#changeLog{
    background-image: url(../../assets/semi-circle.png);
    background-repeat: no-repeat;
    margin-top: 0px;
    background-position-x: center;
}
#changeLog h2{
    text-align: center;
    color: #fff;
    padding: 30px 0px 24px 0px;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 26px;

}
#changeLog .mid-container{
    background: #fff;
    padding: 30px;
    border-radius: 13px;
    box-shadow: 0 14px 22px 0 rgb(0 0 0 / 10%);

}
#changeLog .mid-container .first-head{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 15px;
    margin-bottom: 30px;
}
#changeLog .mid-container .first-head .fab-icon-img{
    width: 65px;
}
#changeLog .mid-container .first-head h4{
    font-size: 22px;
}
#changeLog .mid-container .first-head h6{
    font-size: 12px;
    margin-left: 3px;
    color: gray;
}

#changeLog .mid-container .log-block{
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 15px;
    margin-bottom: 30px;
}

#changeLog .mid-container .log-block h3{
    border-left: 4px solid black;
    padding: 10px;
    text-transform: uppercase;
    color: black;
    font-size: 21px;
}
#changeLog .mid-container .log-block li{
    line-height: 40px;
}
#changeLog .mid-container .log-block ul{
    padding-left: 24px;

}